import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  CardHeader,
  Button,
  Form,
  Modal,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";


import {
  deleteRequest,
  postRequest,
  pushUpdates,
  putRequest,
} from "../../components/Common/Utils.js";
import Select from "react-select";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import noImage from "../../assets/images/No_Image_Available.jpg";
import VenueModal from "../../components/Modal/VenueModal.js";
import OfferModal from "../../components/Modal/OfferModal.js";
import Countdown from "./CountDown.js";
import ActivityModal from "../../components/Modal/ActivityModalId.js";
import EventModal from "components/Modal/EventModalId.js";
import Lightbox from "react-image-lightbox";
import DragDropModal from "./DragDropModal.js";
import VoucherModal from "components/Modal/VoucherModal.js";
import VideoModal from "components/Modal/VideoModal.js";
import FileUpload from "components/Common/FileUpload.js";
import BannerModal from "components/Modal/BannerModal.js";
import CustomModal from "components/Modal/CustomModal.js";
import CustomImageModal from "components/Modal/CustomImageModal.js";
import PollModal from "components/Modal/PollModal.js";
import DaysEditor from "./DaysEditor.js";

export default function UpdateHomeBlock() {
  const location = useLocation();
  const state = location.state?.row;
  const [customDataVenue, setDataVenue] = useState([]);
  const [customDataOffer, setDataOffer] = useState([]);
  const [customDataDeal, setDataDeal] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [customDataComponent, setDataComponent] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [coverLogo, setCoverLogo] = useState([]);
  const [offerListDetail, setOfferListDetail] = useState([]);
  const [venueListDetail, setVenueListDetail] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [modelDeal, setModelDeal] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [modelActivity, setModelActivity] = useState(false);
  const [modelEvent, setModelEvent] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [dragDropData, setDragDropData] = useState([]);

  const [defaultCategory, setDefaultCategory] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [pollModal, setPollModal] = useState(false);

  const [vouchars, setVouchars] = useState([]);

  const [homeData, setHomeBlockData] = useState({});
  const [loading, setLoading] = useState(false);
  const [homeId, setSelectedId] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [categoryoptions, setcategoryOptions] = useState([]);

  const [venueList, setVenueList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [idName, setIdName] = useState("venueId");
  const [typecustom, setTypeCustom] = useState("");

  const [venueoptions, setvenueOptions] = useState([]);
  const [offeroptions, setofferOptions] = useState([]);

  const [sizeList, setSizeList] = useState([]);
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedSlider, setSelectedSlider] = useState([]);

  const [defaultVenue, setDefaultVenue] = useState([]);
  const [defaultVideo, setDefaultVideo] = useState([]);
  const [defaultComponent, setDefaultComponent] = useState([]);
  const [defaultOffer, setDefaultOffer] = useState([]);

  const [daysValue, setDaysValue] = useState(state?.days || [])

  const [venueLoaded, setVenueLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [customCompLoaded, setCustomCompLoaded] = useState(false);
  const [offerLoaded, setOfferLoaded] = useState(false);
  const [modelcustom, setModelCustom] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [editVenueIndex, setVenueIndex] = useState(null);
  const [editOfferIndex, setOfferIndex] = useState(null);
  const [addVenue, setVenueAdd] = useState(null);
  const [typeCustomvalue, setTypeEdit] = useState([]);

  const [dealList, setDealList] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [defaultDeals, setDefaultDeals] = useState([]);
  const [dealLoaded, setDealLoaded] = useState(false);

  const [lowestDiscount, setLowestDiscount] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const [activityPreview, setActivityPreview] = useState([]);
  const [eventPreview, setEventPreview] = useState([]);
  const [activityId, setActivityId] = useState([]);
  const [eventId, setEventId] = useState([]);
  const [modelVoucher, setModelVoucher] = useState(false);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [uploadedVideo, setUploadedVideo] = useState([]);
  const [videoMdl, setVideoMdl] = useState(false);

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [eventLoaded, setEventLoaded] = useState(false);

  const [categoryList, setCategoryList] = useState([]);

  const [startLoader, setStartLoader] = useState(false);


  const [bannerModal, setBannerModal] = useState(false);
  const [bannerId, setBannerId] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  const [customModal, setCustomModal] = useState(false);
  const [customModalImage, setCustomModalImage] = useState(false);
  const [customModalId, setCustomModalId] = useState("");
  const [customModalText, setCustomModalText] = useState("");

  const [ customImageModal , setCustomImageModal ] = useState(false);
  const [ customImageModalImage , setCustomImageModalImage ] = useState(false);
  const [ customImageModalId , setCustomImageModalId ] = useState("");
  const [ customImageModalText , setCustomImageModalText ] = useState("");

  const [selectedPollIds, setSelectedPollIds] = useState([]);
    const [selectedPolls, setSelectedPolls] = useState([]);

  useEffect(() => {
    if (!homeData?.stateLoaded) return;
    HomeBlockFetchDetail();
  }, [homeData?.stateLoaded]);

  useEffect(() => {
    //console.log("uploadedVide updated", uploadedVideo);
  }, [uploadedVideo]);

  const AddUploadedVideo = (video) => {
    setUploadedVideo([...uploadedVideo, video]);
  };

  const closeVideoModal = () => {
    setVideoMdl(false);
  };

  useEffect(() => {
    fetchTheme();
  }, [selectedTheme]);

  useEffect(() => {
    fetchMusic();
  }, [selectedMusic]);

  useEffect(() => {
    fetchFeature();
  }, [selectedFeature]);

  useEffect(() => {
    fetchCuisine();
  }, [selectedCuisine]);

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(ids);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(ids);
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCuisineList(options);
      if (selectedCuisine.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCuisine.includes(item.id);
        });
        setDefaultCuisine(defaultItems);
      }
    }
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setThemeList(options);

      if (selectedTheme.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedTheme.includes(item.id);
        });
        setDefaultTheme(defaultItems);
      }
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);

      if (selectedMusic.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedMusic.includes(item.id);
        });
        setDefaultMusic(defaultItems);
      }
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setFeatureList(options);
      if (selectedFeature.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedFeature.includes(item.id);
        });

        setDefaultFeature(defaultItems);
      }
    }
  };

  const pushSelectedDeal = (deal) => {
    const isExist = selectedDeals.find((x) => x._id === deal._id);

    if (isExist) {
      toast.error("Deal already added");
      return;
    }
    const selectedDealsCopy = [...selectedDeals];
    selectedDealsCopy.push(deal);
    setSelectedDeals(selectedDealsCopy);
    closeModalVoucher();
  };

  const popSelectedDeal = (index) => {
    const selectedDealsCopy = [...selectedDeals];
    selectedDealsCopy.splice(index, 1);
    setSelectedDeals(selectedDealsCopy);
  };

  const [formValues, setFormValues] = useState([]);
  const [currentForm, setCurrentForm] = useState({
    title: "",
    subTitle: "",
    description: "",
    info: "",
    badge: "",
    typeselect: "",
  });

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateOffer = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedOffer(ids);
  };

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
    fetchVideoList();
    fetchComponentList();
    fetchSizeList();
    fetchDealList();
    //HomeBlockFetchDetail();
    fetchCategoryList();
    fetchCategoryList2();
  }, []);

  useEffect(() => {
    if (!defaultDeals?.length) return;
    voucherManagment(defaultDeals);
  }, [defaultDeals]);

  const voucherManagment = async (deals) => {
    const payload = {
      ids: deals,
    };

    const response = await postRequest(`homeblock/deal-package/list`, payload);
    const data = response.data;
    setSelectedDeals(data.list);
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  useEffect(() => {
    if (!selectedCategory?.length) return;
    fetchCategoryList2();
  }, [selectedCategory]);

  const fetchCategoryList2 = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory?.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };

  useEffect(() => {
    if (!state) return;

    setSelectedVenue(state.venues);
    setSelectedComponent(state.customComponents);
    setSelectedId(state._id);
    setSelectedVideo(state.videos);
    setSelectedOffer(state.offers);
    setDefaultDeals(state.deals);
    setSelectedEvents(state.events);

    setBannerId(state.bannerData?._id ? state.bannerData._id : "");
    setBannerText(state.bannerData?.title ? state.bannerData.title : "");
    setBannerImage(state.bannerData?.items?.length ?  state.bannerData.items?.[0].image : "");

    setCustomImageModalText(state.customImageData?.title ? state.customImageData.title : "");
    setCustomImageModalId(state.customImageData?._id ? state.customImageData._id : "");
    setCustomImageModalImage(state.customImageData?.image ? state.customImageData?.image : "");

    setCustomModalText(state.customData?.title ? state.customData.title : "");
    setCustomModalId(state.customData?._id ? state.customData._id : "");
    setCustomModalImage(state.customData?.image ? state.customData?.image : "");

    if (!state.venues?.length) {
      fetchList();
    }
    if (!state.videos?.length) {
      fetchVideoList();
    }
    if (!state.deals?.length) {
      fetchDealList();
    }
    if (state.customComponents?.length) {
      fetchComponentList();
    }
    if (!state.offers?.length) {
      fetchOfferList();
    }
    setSelectedTheme(
      state?.venueFilterForAuto?.themes ? state?.venueFilterForAuto?.themes : []
    );
    setSelectedMusic(
      state?.venueFilterForAuto?.musics ? state?.venueFilterForAuto?.musics : []
    );
    setSelectedFeature(
      state?.venueFilterForAuto?.features
        ? state?.venueFilterForAuto?.features
        : []
    );
    setSelectedCuisine(
      state?.venueFilterForAuto?.cuisines
        ? state?.venueFilterForAuto?.cuisines
        : []
    );

    const defaultItems = state?.categoryForOffer ? state?.categoryForOffer : [];

    setSelectedCategory([...defaultItems]);

    //console.log("state-isauto", state?.isAuto);

    if (state?.isAuto === true) {
      state.isAuto = "1";
    } else {
      state.isAuto = "0";
    }

    // //console.log("state", state.showTitle)

    if (state?.showTitle === false) {
      state.showTitle = false;
    } else {
      state.showTitle = true;
    }

    state.stateLoaded = true;
    setHomeBlockData({ ...state });
    setUploadedVideo([...state?.videos]);
    //console.log("state", state);
  }, [state]);

  useEffect(() => {
    if (!selectedVenue?.length) return;
    if (venueLoaded) return;
    fetchList();
  }, [selectedVenue]);

  useEffect(() => {
    if (!selectedVideo?.length) return;
    if (videoLoaded) return;
    fetchVideoList();
  }, [selectedVideo]);

  useEffect(() => {
    if (!selectedDeals?.length) return;
    if (dealLoaded) return;
    fetchDealList();
  }, [selectedDeals]);

  useEffect(() => {
    if (!selectedEvents?.length) return;
    if (eventLoaded) return;
    fetchEventList();
  }, [selectedEvents]);

  useEffect(() => {
    if (!selectedComponent?.length) return;
    if (customCompLoaded) return;
    fetchComponentList();
  }, [selectedComponent]);

  useEffect(() => {
    if (!selectedOffer?.length) return;
    if (offerLoaded) return;
    fetchOfferList();
  }, [selectedOffer, customDataVenue]);

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setcategoryOptions(response.data.list);
    }
    setLoading(false);
  };

  const fetchSizeList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/size/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          type: item.type,
          ratio: item.ratio,
        };
      });
      setSizeList(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    // need to calculate lowest discount discount is string so we need to convert it to number

    var lowest = 100000;
    if (vouchars.length > 0) {
      lowest = vouchars.reduce((prev, curr) => {
        return parseFloat(prev.discountValue) < parseFloat(curr.discountValue)
          ? prev
          : curr;
      });
    }
    setLowestDiscount(lowest ? lowest.discountValue : "");
  }, [vouchars]);

  const addVoucher = () => {
    setVouchars([...vouchars, { saved: false }]);
    setEditIndex(vouchars.length);
  };

  const fetchOfferList = async () => {
    //console.log("selected-offers", selectedOffer);

    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setOfferList(list);
      const mappedOptions = response.data.list.map((item) => ({
        value: item._id,
        label: item.title,
      }));
      setofferOptions(mappedOptions);
      setOfferListDetail(response.data.list);

      // //console.log("selected-offers",response.data.list)

      const defaultItems = response.data.list.filter((item) => {
        return selectedOffer.includes(item?._id?.toString());
      });

      let array = [];

      defaultItems.forEach((element) => {
        let arr = [],
          cover,
          logo,
          address,
          label,
          venue,
          title,
          image,
          description,
          _id;
        title = element.title;
        _id = element._id;
        description = element.description;
        cover = element.image;
        venue = element.venue;
        logo = element.venue.logo;
        image = element.image;
        address = element.venue.address;
        label = element.venue.name;
        arr = {
          cover,
          logo,
          address,
          label,
          title,
          description,
          _id,
          image,
          venue,
        };
        array.push(arr);
      });

      //console.log("default-items", array);
      // setOfferData([...array]);
      setOfferLoaded(true);
      //setDefaultOffer(defaultItems);
    }
    setLoading(false);
  };

  const updateCategory = (e) => {
    setCurrentForm((prevForm) => ({
      ...prevForm,
      category: e.value,
    }));
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);
      const mappedOptions = response.data.list.map((item) => ({
        value: item._id,
        label: item.name,
      }));
      setVenueListDetail(response.data.list);
      setvenueOptions(mappedOptions);

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });
      setVenueLoaded(true);
      setDefaultVenue(defaultItems);
    }
    setLoading(false);
  };

  const fetchVideoList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/video/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setVideoList(list);

      const defaultItems = list.filter((item) => {
        return selectedVideo.includes(item.id);
      });
      setDefaultVideo(defaultItems);
    }

    setVideoLoaded(true);

    setLoading(false);
  };

  const toggleModelVoucher = () => {
    setModelVoucher(!modelVoucher);
  };

  const closeModalVoucher = () => {
    setModelVoucher(false);
  };

  const fetchDealList = async () => {
    return;
    setLoading(true);
    const response = await postRequest("homeblock/deal/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setDealList(list);

      const defaultItems = list.filter((item) => {
        return selectedDeals.includes(item.id);
      });
      //setDefaultDeals(defaultItems);
    }
    setDealLoaded(true);
    setLoading(false);
  };

  const fetchEventList = async () => {
    setLoading(true);
    const response = await postRequest("event/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });

      const defaultItems = response.data.list.filter((item) => {
        return selectedEvents.includes(item?._id?.toString());
      });

      defaultItems.forEach((element) => {
        let selectRow = element;

        let arr = [],
          cover,
          logo,
          address,
          label,
          title,
          discount,
          description;
        title = selectRow.title;
        description = selectRow?.description;
        cover = selectRow?.image;
        logo = selectRow?.orgLogo;
        address = selectRow?.orgAddress;
        discount = selectRow?.pack?.discountValue;

        label = selectRow?.orgName;

        arr = { cover, label, logo, address, title, description, discount };
        // setEventPreview([...eventPreview, arr]);
        // setEventId([...eventId, selectRow._id]);
      });

      setEventLoaded(true);
    }
    setLoading(false);
  };

  const fetchComponentList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/custom/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setComponentList(list);

      const defaultItems = list.filter((item) => {
        return selectedComponent.includes(item.id);
      });
      setCustomCompLoaded(true);
      setDefaultComponent(defaultItems);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    let length = venueData.length;
    setVenueIndex(length);
    setModel(!model);
  };
  const toggleModel2 = () => {
    let length = offerData.length;
    setModel2(!model2);
    setOfferIndex(length);
  };
  const EdittoggleModelVenue = (index) => {
    const setindex = index;
    setVenueIndex(setindex);
    setModel(!model);
  };
  const EdittoggleModelOffer = (index) => {
    const setindex = index;
    setOfferIndex(setindex);
    setModel2(!model2);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const closeModal = () => {
    setModel(false);
    setModelDeal(false);
    setModelCustom(false);
  };
  const closeModal4 = () => {
    setModelEvent(false);
  };

  const saveVoucher = (index) => {
    setVouchars(
      vouchars.map((voucher, i) =>
        i === index ? { ...voucher, saved: true } : voucher
      )
    );
    setEditIndex(null);
  };

  const selectRow = (selectRow, id) => {
    let arr = [],
      cover,
      logo,
      address,
      name,
      _id;

    cover = selectRow.cover;
    _id = id;
    logo = selectRow.logo;
    address = selectRow.address;
    name = selectRow.name;
    const newArray = [...venueData];
    newArray[editVenueIndex] = { cover, logo, address, name, _id };
    setVenueData(newArray);
    closeModal();
  };
  const selectRow2 = (selectRow2, id) => {

    console.log("selectRow2", selectRow2);

    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description,
      _id;
    title = selectRow2.title;
    _id = id;
    description = selectRow2.description;
    cover = selectRow2.image;
    logo = selectRow2.venue.logo;
    address = selectRow2.venue.address;
    label = selectRow2.venue.name;
    arr = { cover, logo, address, label, title, description, _id };
    const newArray = [...offerData];

    newArray[editOfferIndex] = selectRow2;
    setOfferData(newArray);
    closeModal2();
  };

  const selectRow2Multiple = (selectRows, ids) => {
    // Create an array to store the transformed rows

    console.log("selectRows", selectRows);

    const transformedRows = selectRows.map((row) => {
      const cover = row.image;
      const logo = row.venue.logo;
      const address = row.venue.address;
      const label = row.venue.name;
      const title = row.title;
      const description = row.description;
  
      // Return the transformed object
      return { cover, logo, address, label, title, description, _id: row._id, venue: row.venue, image: row.image };
    });
  
    // Update the offerData state by appending the transformed rows
    setOfferData((prevData) => [...prevData, ...transformedRows]);
  
    // (Optional) If you have a separate state for IDs, you can update it here
    // setOfferId((prevIds) => [...prevIds, ...ids]);
  
    // Close the modal
    closeModal2();
  };

  const createHomeBlock = async (e) => {
    e.preventDefault();
    //console.log({ offerData });
    // if (!homeData.size) {
    //   //homeData.size = "64743d1ba021f3bf326ab3ce";

    //   toast.error("Please select video");
    //   return;https://dashboard.7nightsuae.com/commission-report

    //   return;
    // }

    //console.log("homeData", homeData);

    if (homeData.type != "video" && homeData.type != "my-outing") {
      if (!homeData.title) {
        toast.error("Title is required");
        return;
      }
    }

    if (homeData.type == "venue" && !homeData.size) {
      toast.error("Please select video");
      return;
    }

    if (homeData.type == "video" && !uploadedVideo.length) {
      toast.error("Please select video");
      return;
    }
    let venueIds = [];
    if (
      homeData.type == "venue" &&
      !venueData?.length &&
      homeData?.isAuto != "1"
    ) {
      toast.error("Please select venue");
      return;
    } else {
      venueData?.map((item) => {
        venueIds.push(item._id);
      });
    }

    if (homeData.type == "activity" && !activityId.length) {
      toast.error("Please select activity");
      return;
    }

    if (
      homeData.type == "event" &&
      !eventId.length &&
      homeData?.isAuto != "1"
    ) {
      toast.error("Please select event");
      return;
    }

    // if (homeData.type == "venue" && !selectedVenue?.length) {
    //   toast.error("Please select venue");
    //   return;
    // }
    let offerIds = [];

    if (
      homeData.type == "offer" &&
      !offerData?.length &&
      homeData?.isAuto != "1"
    ) {
      toast.error("Please select offer");
      return;
    } else {
      offerData.map((item) => {
        offerIds.push(item._id);
      });
    }

    if (homeData.type == "slider" && !selectedSlider?.length) {
      toast.error("Please select slider");
      return;
    }

    if (homeData.type == "deal" && !selectedDeals.length) {
      toast.error("Please add deals");
      return;
    }

    setLoading(true);

    let uVideos = uploadedVideo?.map((video) => video._id);
    //return;

    const dealIds = selectedDeals.map((deal) => deal._id);

    const payload = {
      ...homeData,
      id: homeId,
      offers: offerIds,
      sliders: selectedSlider,
      videos: uVideos,
      customComponents: selectedComponent,
      activities: homeData.type == "activity" ? activityId : [],
      events: homeData.type == "event" ? eventId : [],
      venues: venueIds,
      polls: homeData.type == "poll" ? selectedPollIds : [],
      // venues: homeData.isAuto == "1" ? [] : venueIds,
      categoryForOffer: selectedCategory,
      customVenues: homeData.type == "custom-venue" ? customDataVenue : [],
      customOffers: homeData.type == "custom-offer" ? customDataOffer : [],
      deals: homeData.type == "deal" ? dealIds : [],
    };

    if (homeData.type == "venue" && homeData?.isAuto == "1") {
      let currentCategoryForAuto = {
        themes: selectedTheme,
        musics: selectedMusic,
        features: selectedFeature,
        cuisines: selectedCuisine,
      };
      payload.venueFilterForAuto = currentCategoryForAuto;
    }

    // let url = "story/create";
    // if(storyData.mediaType == 'video'){
    //   url = "story/create/video";
    // }

    if(homeData.type = "days"){
      payload.days = daysValue;
    }

    const response = await postRequest("homeblock/update", payload);
    setLoading(false);
    if (response.status === 1) {
      // If the update is successful, call the pushUpdates function

      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "home-block",
          id: id,
          receivers: [],
        });
      } catch (error) {
        //console.log("chat event not worked");
      }

      toast.success("Home Block updated successfully");
      setTimeout(() => {
        history.push("/homeblock");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...homeData };
    list[index] = value;
    if (index == "type") {
      if (value == "custom-offer") {
        setTypeCustom("custom");
        setIdName("offerId");
        setCoverLogo([]);
      } else if (value == "custom-venue") {
        setIdName("venueId");
        setTypeCustom("custom");
        setCoverLogo([]);
      } else {
        setTypeCustom("");
      }
      if (value == "event") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "recent";
      }
      if (value == "venue") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "all";
      }
      if (value == "offer") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "all";
      }
      if (value != "venue" && value != "event" && value != "offer") {
        list["isAuto"] = "0";
        list["categoryForAuto"] = "";
      }
    }
    setHomeBlockData({ ...list });
  };

  const [numFieldsVenue, setNumFieldsVenue] = useState(1);
  const [numFieldsOffer, setNumFieldsOffer] = useState(1);
  const handleAddFieldVenue = () => {
    setNumFieldsVenue(numFieldsVenue + 1);
  };
  const handleAddFieldOffer = () => {
    setNumFieldsOffer(numFieldsOffer + 1);
  };

  const handleInputChange = (e, index, field) => {
    const newVouchers = [...vouchars];
    newVouchers[index][field] = e.target.value;
    setVouchars(newVouchers);
  };

  const handleRemoveVideo = (index) => {
    let newUploadedVideo = uploadedVideo.filter((_, idx) => idx !== index);
    setUploadedVideo(newUploadedVideo);
    // let tempUploaded = [...uploadedVideo]
    // tempUploaded = tempUploaded.filter((item) => item._id != id);
    // setUploadedVideo([...tempUploaded]);
  };

  const handleRemoveFieldVenue = (index) => {
    if (index > 0) {
      if (customDataVenue?.length > index) {
        let data = customDataVenue.slice(index);
        setDataVenue(data);
      }
      setNumFieldsVenue(numFieldsVenue - 1);
    }
    delete coverLogo[index];
  };

  const handleRemoveFieldOffer = (index) => {
    if (index > 0) {
      if (customDataOffer?.length > index) {
        let data = customDataOffer.slice(index);
        setDataOffer(data);
      }
      setNumFieldsOffer(numFieldsOffer - 1);
    }
    delete coverLogo[index];
  };

  const deleteVoucher = (index) => {
    setVouchars(vouchars.filter((_, i) => i !== index));
  };

  const HomeBlockFetchDetail = async () => {
    postRequest(`homeblock/detail`, { id: `${id}` }).then((data) => {
      if (data.data[0].type == "custom-venue") {
        let homeblockdata = data.data[0].customVenues;
        let homeblockdata1 = data.data[0];
        setDataVenue(homeblockdata);
        setNumFieldsVenue(homeblockdata?.length);
        setTypeCustom("custom");
        setIdName("venueId");
      } else if (data.data[0].type == "custom-offer") {
        let homeblockdata = data.data[0].customOffers;
        setDataOffer(homeblockdata);
        setNumFieldsOffer(homeblockdata?.length);
        setTypeCustom("custom");
        setIdName("offerId");
      } else if (data.data[0].type == "custom-components") {
        let homeblockdata = data.data[0].customComponents;
        setDataComponent(homeblockdata);
        setNumFieldsOffer(homeblockdata?.length);
        setTypeCustom("custom");
        setIdName("offerId");
      } else if (data.data[0].type == "venue") {
        let homeblockdata = [...venueData];
        const list = { ...homeData };
        let isAuto = data.data[0].isAuto;
        let venues = data.data[0].venues || [];
        list.isAuto = isAuto ? "1" : "0";
        list.type = "venue";
        list.promoteVenue = venues.length != 0 ? "1" : "0";
        setHomeBlockData(list);
        homeblockdata = data.data[0].venues;
        setVenueData(homeblockdata);
        setNumFieldsOffer(homeblockdata?.length);
        // setTypeCustom('custom');
        // setIdName("offerId");
      } else if (data.data[0].type == "activity") {
        let homeblockdata = data.data[0].activities;
        const ids = homeblockdata.map((item) => item._id);
        homeblockdata = homeblockdata.map((item) => {
          return {
            ...item,
            activityId: item._id,
            cover: item.provider.logo,
            logo: item.provider.logo,
            address: item.provider.address,
            label: item.provider.name,
            description: item.description,
            title: item.name,
            discount: item.discount,
          };
        });
        setActivityPreview(homeblockdata);
        setActivityId(ids);
        setNumFieldsOffer(homeblockdata?.length);
      } else if (data.data[0].type == "event") {
        let homeblockdata = data.data[0].events;
        const list = { ...homeData };
        let isAuto = data.data[0].isAuto;
        let events = data.data[0].events || [];
        list.isAuto = isAuto ? "1" : "0";
        list.type = "event";
        list.promoteEvent = events.length != 0 ? "1" : "0";
        setHomeBlockData(list);
        const ids = homeblockdata.map((item) => item._id);

        homeblockdata = homeblockdata.map((item) => {
          return {
            ...item,
            eventId: item._id,
            cover: item.image,
            logo: item.orgData?.logo,
            address: item.orgData?.website,
            label: item.orgData.name,
            description: item.description,
            title: item.title,
            discount: "",
          };
        });
        //console.log({ ids });
        setEventPreview(homeblockdata);
        setEventId(ids);
        setNumFieldsOffer(homeblockdata?.length);
        // setTypeCustom('custom');
        // setIdName("offerId");
      } else if (data.data[0].type == "offer") {
        let homeblockdata = data.data[0].offers;
        const list = { ...homeData };
        let offers = data.data[0].offers || [];
        list.type = "offer";
        list.promoteOffer = offers.length != 0 ? "1" : "0";
        setOfferData(homeblockdata);
        setHomeBlockData(list);
        setNumFieldsOffer(homeblockdata?.length);
      }else if(data.data[0].type == "poll") {
        let homeblockdata = data.data[0].polls;
        setSelectedPolls(homeblockdata);
        setSelectedPollIds(homeblockdata.map((poll) => poll._id));
      
      }else if (data.data[0].type == "deal") {
        let homeblockdata = data.data[0].deals;
        setDataDeal(homeblockdata);
        setNumFieldsOffer(homeblockdata?.length);
      }
    });
  };
  const handleCoverChange = (e, index) => {
    let arr = [],
      cover,
      bid,
      logo,
      address,
      label;
    arr = arr.concat(coverLogo);
    let value = e.value;
    let title = e.label;

    if (homeData?.type == "custom-venue" || homeData?.type == "deal") {
      let result = venueListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.cover;
      logo = result.logo;
      address = result.address;
      label = title;
      bid = value;
    } else if (homeData?.type == "custom-offer") {
      let result = offerListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.venue.cover;
      logo = result.venue.logo;
      address = result.venue.address;
      label = title;
    }

    let newObj = {
      ...currentForm,
      cover,
      logo,
      address,
      label,
      bid,
      [idName]: value,
    };
    //setCoverLogo(newObj);
    setCurrentForm(newObj);
  };

  const toggleModelCustom = () => {
    if (homeData?.type == "custom-venue") {
      let length = customDataVenue.length;
      setCurrentIndex(length);
    } else if (homeData?.type == "custom-offer") {
      let length = customDataOffer.length;
      setCurrentIndex(length);
    } else if (homeData?.type == "custom-components") {
      let length = customDataComponent.length;
      setCurrentIndex(length);
    }

    setModelCustom(!modelcustom);
  };

  const toggleModelDeal = () => {
    let length = customDataDeal.length;
    setCurrentIndex(length);
    setModelDeal(!modelDeal);
  };


  const closePollModal = () => {
    setPollModal(false);
  }

  const togglePollModal = () => {
    setPollModal(!pollModal);
  };

  const deletePoll = (id) => {
    const newPolls = selectedPolls.filter((poll) => poll._id !== id);
    setSelectedPollIds(selectedPollIds.filter((pollId) => pollId !== id));
    setSelectedPolls(newPolls);
  }

   const selectPollRow = (selectRow, id) => {
  
      console.log({ selectRow, id });
  
      // check that poll is already added
      const isPollAlreadyAdded = selectedPollIds.includes(id);
  
      if (isPollAlreadyAdded) {
        toast.error("Poll already added");
        return;
      }
  
      setPollModal(false);    
      setSelectedPollIds(
        [...selectedPollIds, id]     
      );
      setSelectedPolls( [...selectedPolls, selectRow] );
    }


  const EditModelCustom = (item, index) => {
    if (homeData?.type == "custom-venue") {
      const myObject = {
        label: item.label || item.forSelect.label,
        value: item.venueId || item.forSelect.value,
      };
      setTypeEdit(myObject);
    } else if (homeData?.type == "custom-offer") {
      const myObject = {
        label: item.label || item.forSelect.label,
        value: item.offerId || item.forSelect.value,
      };
      setTypeEdit(myObject);
    } else if (homeData?.type == "deal") {
      const myObject = {
        label: item?.label || item?.forSelect?.label,
        value: item?.dealId || item?.forSelect?.value,
      };

      setSelectedCategory({
        label: item?.category?.title,
        value: item?.categoryId,
      });

      setTypeEdit(myObject);
      // let arr = [], label,value
      // label = item.label;
      // value = item.venueId;
      // setTypeEdit([...arr])

      setCurrentIndex(index);
      setCurrentForm(item);

      setFormValues((prevValues) => [...prevValues, item]);
      setVouchars(item.vouchars);
      setModelDeal(!modelDeal);
      return;
    }

    setCurrentIndex(index);
    // let arr = [], label,value
    // label = item.label;
    // value = item.venueId;
    // setTypeEdit([...arr]);

    setCurrentForm(item);
    setFormValues((prevValues) => [...prevValues, item]);
    setModelCustom(!modelcustom);
  };
  const closeModalCustom = () => {
    setModelCustom(false);
    setCurrentForm({
      title: "",
      subTitle: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
    });
  };

  const handleRemoveFormValue = (index, id = false) => {
    if (homeData?.type == "custom-venue") {
      const newArray = [...customDataVenue];
      newArray.splice(index, 1);
      setDataVenue(newArray);
    } else if (homeData?.type == "custom-offer") {
      const newArray = [...customDataOffer];
      newArray.splice(index, 1);
      setDataOffer(newArray);
    } else if (homeData?.type == "deal") {
      const newArray = [...customDataDeal];
      newArray.splice(index, 1);
      setDataDeal(newArray);
      if (id) {
        deleteDeal(id);
      }
    }
  };

  const deleteDeal = async (id) => {
    await deleteRequest("home-block/deal/delete", {
      dealId: id,
    });
  };

  const deleteDealVouchar = async (deal, id) => {
    await deleteRequest("home-block/deal/vouchar/delete", {
      dealId: deal,
      voucharId: id,
    });
  };

  const CustomFormValue = (event) => {
    event.preventDefault();
    if (homeData?.type == "custom-venue") {
      const newArray = [...customDataVenue];
      newArray[currentIndex] = currentForm;
      setDataVenue(newArray);
    } else if (homeData?.type == "custom-offer") {
      const newArray = [...customDataOffer];
      newArray[currentIndex] = currentForm;
      setDataOffer(newArray);
    } else if (homeData?.type == "custom-components") {
      const newArray = [...customDataComponent];
      newArray[currentIndex] = currentForm;
      setDataComponent(newArray);
    }
    setModelCustom(!modelcustom);
    setCurrentForm({
      title: "",
      subTitle: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
    });
  };

  const CustomFormDeal = (event) => {
    event.preventDefault();
    //return;
    // setInputs('');
    const newData = {
      ...currentForm,
      title: currentForm.title,
      description: currentForm.description,
      venue: currentForm?.bid,
      category: currentForm.category,
      startDate: currentForm.startDate,
      endDate: currentForm.endDate,
      lowestDiscount: lowestDiscount,
      image: selectedImage,
    };
    newData.vouchars = vouchars;

    // get current form data
    const newArray = [...customDataDeal];
    newArray[currentIndex] = newData;
    setDataDeal(newArray);

    setCurrentForm({
      title: "",
      description: "",
      venue: "",
      startDate: "",
      endDate: "",
    });
    // reset vouchars
    setVouchars([]);
    closeModal();
  };

  const [customdata, setData] = useState([]);
  const pushData = (newData) => {
    setData((prevData) => [...prevData, newData]);
  };

  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setCurrentForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleRemovevenuPreview = (index) => {
    const newArray = [...venueData];
    newArray.splice(index, 1);
    setVenueData(newArray);
  };
  const handleRemoOfferPreview = (index) => {
    const newArray = [...offerData];
    newArray.splice(index, 1);
    setOfferData(newArray);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModelEvent = () => {
    setModelEvent(!modelEvent);
  };

  const closeModal3 = () => {
    setModelActivity(false);
  };

  const selectActivityRow = (selectRow, id) => {
    // check if activity is already added

    const isActivityAlreadyAdded = activityId.includes(id);

    if (isActivityAlreadyAdded) {
      toast.error("Activity already added");
      return;
    }

    closeModal3();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;
    title = selectRow.name;
    description = selectRow?.description?.description;
    cover = selectRow.provider?.logo;
    logo = selectRow.provider?.logo;
    address = selectRow.provider?.address;
    label = selectRow.provider?.name;
    arr = { cover, logo, address, label, title, description };
    setActivityPreview([...activityPreview, arr]);
    setActivityId([...activityId, id]);
  };

  const handleRemoveActivityPreview = (index) => {
    const newArray = [...activityPreview];
    newArray.splice(index, 1);
    setActivityPreview(newArray);
    const newvenue = [...activityId];
    newvenue.splice(index, 1);
    setActivityId(newvenue);
  };

  const selectEventRow = (selectRow, id) => {
    // check if activity is already added

    const isEventAlreadyAdded = eventId.includes(id);

    if (isEventAlreadyAdded) {
      toast.error("Event already added");
      return;
    }

    closeModal4();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      discount,
      description;
    title = selectRow.title;
    description = selectRow?.description;
    cover = selectRow?.image;
    logo = selectRow?.orgLogo;
    address = selectRow?.orgAddress;
    discount = selectRow?.pack?.discountValue;

    label = selectRow?.orgName;

    arr = { cover, label, logo, address, title, description, discount };
    setEventPreview([...eventPreview, arr]);
    setEventId([...eventId, id]);
  };

  const handleRemoveEventPreview = (index) => {
    const newArray = [...eventPreview];
    newArray.splice(index, 1);
    setEventPreview(newArray);
    const newvenue2 = [...eventId];
    newvenue2.splice(index, 1);
    setEventId(newvenue2);
  };

  const updateDragDropData = async (data) => {
    setDragDropData(data);
    if (homeData.type == "venue") {
      setVenueData(data);
    } else if (homeData.type == "offer") {
      setOfferData(data);
    } else if (homeData.type == "video") {
      setUploadedVideo(data);
    } else if (homeData.type == "custom-venue") {
      setDataVenue(data);
    } else if (homeData.type == "custom-offer") {
      setDataOffer(data);
    } else if (homeData.type == "event") {
      setEventPreview(data);
    } else if (homeData.type === "activity") {
      setActivityPreview(data);
    }
    //console.log(data);
    setShowOrder(false);
  };

  const DragDropTable = () => {
    if (homeData.type == "venue") {
      setDragDropData(venueData);
    } else if (homeData.type == "offer") {
      //console.log({ offerData });
      setDragDropData(offerData);
    } else if (homeData.type == "video") {
      setDragDropData(uploadedVideo);
    } else if (homeData.type == "custom-venue") {
      setDragDropData(customDataVenue);
    } else if (homeData.type == "custom-offer") {
      // setMergeformData(data);
      setDragDropData(customDataOffer);
    } else if (homeData.type == "event") {
      // setMergeformData(data);
      setDragDropData(eventPreview);
    } else if (homeData.type == "activity") {
      // setMergeformData(data);
      setDragDropData(activityPreview);
    }
    setShowOrder(true);
  };

  const closeBannerModal = () => {
    setBannerModal(false);
  }

  const closeCustomModal = () => {
    setCustomModal(false);
  }

  const closeCustomImageModal = () => {
    setCustomImageModal(false);
  }

  const toggleBannerModal = () => {
    setBannerModal(!bannerModal);
  }

  const toggleCustomModal = () => {
    setCustomModal(!customModal);
  }

  const toggleCustomImageModal = () => {
    setCustomImageModal(!customImageModal);
  }

  const selectBannerRow = (selectRow, id) => {
    //console.log(selectRow);

    setBannerId(selectRow?._id);
    setBannerText(selectRow?.title);
    setBannerImage(selectRow?.items[0]?.image);
    closeBannerModal();

  }

  const selectCustomRow = (selectRow, id) => {
    //console.log(selectRow);
    setCustomModalId(selectRow?._id);
    setCustomModalText(selectRow?.title);
    setCustomModalImage(selectRow?.image);
    closeCustomModal();
  }

  const selectCustomImageRow = (selectRow, id) => {
    //console.log(selectRow);
    setCustomImageModalImage(selectRow?.image);
    setCustomImageModalId(selectRow?._id);
    setCustomImageModalText(selectRow?.title);
    closeCustomImageModal();
  }


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Homeblock Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Homeblock Management</li>:{" "}
              <li className="breadcrumb-item">Update Homeblock</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Home Block </h4>

                <Form onSubmit={createHomeBlock}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        {/* <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Select Venue</label>
                            <div className="col-sm-6">
                               { venueText ? <a onClick={ () => toggleModel() } href="javascript:void(0)">{venueText}</a> : <a onClick={ () => toggleModel() } href="javascript:void(0)">Select Venue</a> }                                                     
                            </div>                           
                        </div>   */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              defaultValue={homeData.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Show Title in App
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              defaultChecked={homeData.showTitle}
                              onChange={(e) =>
                                updateValue("showTitle", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={homeData.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              value={homeData.type}
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="mediaType"
                              class="form-select"
                            >
                              <option value="video">Video</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                              <option value="days">Days</option> 
                              <option value="deal">Deal</option>    
                              <option value="banner">Banner</option>
                              <option value="poll">Poll</option>
                              <option value="custom_image">Image Component</option>
                              <option value="custom">Custom Component</option>                         
                              <option value="custom-venue">Custom Venue</option>
                              <option value="custom-offer">Custom offer</option>
                              <option value="custom-components">
                                Custom Component
                              </option>
                              <option value="suggested-users">Suggested Users</option> 
                              <option value="suggested-venues">Suggested Venues</option> 
                              <option value="suggested-offers">Suggested Offers</option> 
                              <option value="search-field">Search Field</option> 
                              <option value="nearby-venue">Nearby Venue</option>
                            </select>
                          </div>
                        </div>

                        {homeData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Size
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("size", e.target.value)
                                }
                                defaultValue={homeData.sizeId || ""}
                                class="form-select"
                              >
                                <option> Select</option>
                                {sizeList.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.type + " - " + item.ratio}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "banner" && (

                                                  
                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Select Banner
                        </label>

                          <div
                          className="col-sm-6"
                          onClick={() => toggleBannerModal()}
                        >
                          
                          {bannerText ? (
                          
                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#E22A2A",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleBannerModal()}
                              >
                                Select Banner
                              </button>
                              <div
                                style={{
                                  border: "1px solid #ced4da",
                                  width: "16px",
                                }}
                              ></div>
                              <img
                                src={bannerImage}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  border: "1px solid #ced4da",
                                  borderRight: "none",
                                  borderLeft: "none",
                                }}
                              ></img>
                              <input
                                type="text"
                                style={{ borderLeft: "none" }}
                                class="form-control"
                                readonly
                                value={bannerText}
                                id="selectedVenue"
                                placeholder="No Banner chosen"
                              />
                            </div>
                          ) : (
                            
                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#E22A2A",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleBannerModal()}
                              >
                                Select Banner
                              </button>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                id="selectedVenue"
                                placeholder="No Banner chosen"
                              />
                            </div>
                          )}
                        </div>   
                        </div> 

                        )}


                        {homeData?.type == "custom" && (

                                                  
                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Select Custom Component
                        </label>

                          <div
                          className="col-sm-6"
                          onClick={() => toggleCustomModal()}
                        >
                          
                          {customModalText ? (
                          
                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#E22A2A",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleCustomModal()}
                              >
                                Select Custom Component
                              </button>
                              <div
                                style={{
                                  border: "1px solid #ced4da",
                                  width: "16px",
                                }}
                              ></div>
                              <img
                                src={customModalImage}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  border: "1px solid #ced4da",
                                  borderRight: "none",
                                  borderLeft: "none",
                                }}
                              ></img>
                              <input
                                type="text"
                                style={{ borderLeft: "none" }}
                                class="form-control"
                                readonly
                                value={customModalText}
                                id="selectedVenue"
                                placeholder="No Banner chosen"
                              />
                            </div>
                          ) : (
                            
                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#E22A2A",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleCustomModal()}
                              >
                                Select Custom Component
                              </button>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                id="selectedVenue"
                                placeholder="No Custom Component chosen"
                              />
                            </div>
                          )}
                        </div>   
                        </div> 

                        )}


                                              {homeData?.type == "poll" && (
                                                  <>
                                                   <div className="row align-items-center">
                          {selectedPolls.map((form, index) => (
                            <div className="col-lg-3 mb-3" key={index}>
                              <div
                                className="customcomponent fix-heights"
                                style={{
                                  border: "1px solid #ccc",
                                  textAlign: "start",
                                  backgroundColor: form.cardType === "bg-color" ? form.cardTypeValue : "#1a1729",
                                  borderRadius: "10px",
                                  padding: "20px",
                                  color: "#ffffff",
                                }}
                              >
                                {/* Show Image if cardType is not bg-color */}
                                {form.cardType !== "bg-color" && form.image && (
                                  <div className="image-container" style={{ marginBottom: "10px" }}>
                                    <img
                                      src={form.image}
                                      alt="Poll"
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </div>
                                )}
                        
                                {/* Title and Description */}
                                <div className="dic" style={{ marginBottom: "20px", marginTop: "0px" }}>
                                  <h5 className="title" style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    {form.title ? form.title : "Title"}
                                  </h5>
                        
                                  <div className="mb-3">
                                    <h6 className="fnt-18" style={{ fontSize: "14px" }}>
                                      {form.description ? form.description : "Description"}
                                    </h6>
                                  </div>
                                </div>
                        
                                {/* Options based on Type */}
                                <div className="bottom-footer mt-3">
                                  {form.type === "venue" ? (
                                    <div className="options">
                                      {form?.options?.map((venue, idx) => (
                                        <div
                                          key={idx}
                                          className="d-flex align-items-center mb-3"
                                          style={{
                                            cursor: "pointer",
                                            marginBottom: "10px",
                                            backgroundColor: form.initialButtonColor,
                                            color: form.textColor,
                                            borderRadius: "5px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          <div className="logo">
                                            <img
                                              src={venue.logo || noImage}
                                              alt="Venue"
                                              style={{ width: "40px", height: "40px" }}
                                            />
                                          </div>
                                          <div>
                                            <h3 style={{ fontSize: "16px", marginTop: "12px" }}>{venue.name}</h3>
                                            <p>{ venue.address }</p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form.type === "offer" ? (
                                    <div className="options">
                                      {form?.options?.map((offer, idx) => (
                                        <div
                                          key={idx}
                                          className="d-flex align-items-center mb-3"
                                          style={{
                                            cursor: "pointer",
                                            marginBottom: "15px",
                                            backgroundColor: form.initialButtonColor,
                                            color: form.textColor,
                                            borderRadius: "5px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          <div className="logo">
                                            <img
                                              src={offer?.image || noImage}
                                              alt="Offer"
                                              style={{ width: "40px", height: "40px", marginRight: "10px" }}
                                            />
                                          </div>
                                          <div>
                                            <h3 style={{ fontSize: "16px", marginTop: "12px" }}>{offer.title}</h3>
                                            <p>{offer.description}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form.type === "text" ? (
                                    <div className="text-options">
                                      {form?.options?.map((option, idx) => (
                                        <div
                                          key={idx}
                                          className="d-flex align-items-center mb-3"
                        
                                          style={{
                                            cursor: "pointer",
                                            gap: "10px",
                                            backgroundColor: form.initialButtonColor || "#282828",
                                            borderRadius: "5px",
                                            padding: "5px 12px"
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name={`text-option-${index}`}
                                            id={`option-${index}-${idx}`}
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              accentColor: form.initialButtonColor,
                                             
                                            }}
                                          />
                                          <label
                                            htmlFor={`option-${index}-${idx}`}
                                            style={{
                                              fontSize: "14px",
                                              lineHeight: "1.5",
                                              color: form.textColor,
                                              cursor: "pointer",
                                            }}
                                          >
                                            {option.text}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <div
                                      className="yes-no-options"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                        
                        
                                        {form?.options?.map((option, idx) => (
                                          <button
                                            className="btn-option"
                                            style={{
                                              backgroundColor: form.initialButtonColor,
                                              color: form.textColor,
                                              border: "none",
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {option.text}
                                          </button>
                        
                                      ))}            
                                    </div>
                                  )}
                                </div>
                        
                                {/* Vote Button */}
                                <div className="vote-button-container" style={{ marginTop: "20px" }}>
                                  <button
                                    style={{
                                      backgroundColor: form.voteBottonEnabledBgColor,
                                      color: form.textColor || "#fff",
                                      border: "none",
                                      borderRadius: "5px",
                                      padding: "10px 20px",
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {form.voteBottonEnabledText}
                                  </button>
                                </div>
                              </div>
                        
                              <div style={{ textAlign: "start" }}>
                                <button
                                  type="button"
                                  onClick={() => deletePoll(form._id)}
                                  className="btn-remove"
                                  style={{
                                    backgroundColor: "red",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                        
                        <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={togglePollModal}
                                  className="modal-custom-button mt-3 mb-3"
                                  disabled={selectedPolls.length > 0} // Disable if a poll is selected
                                  style={{
                                    cursor: selectedPolls.length > 0 ? "not-allowed" : "pointer",
                                    opacity: selectedPolls.length > 0 ? 0.6 : 1, // Optional: adjust opacity for a disabled look
                                  }}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                                                  </>
                                                )}

                          {homeData?.type == "custom_image" && (

                                                                            
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Select Custom Image
                          </label>

                            <div
                            className="col-sm-6"
                            onClick={() => toggleCustomImageModal()}
                          >
                            
                            {customImageModalText ? (
                            
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleCustomImageModal()}
                                >
                                  Select Custom Image
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={customImageModalImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={customImageModalText}
                                  id="selectedVenue"
                                  placeholder="No Banner chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleCustomImageModal()}
                                >
                                  Select Custom Image
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Custom Image chosen"
                                />
                              </div>
                            )}
                          </div>   
                          </div> 

                          )}

                        {homeData?.type == "deal" && (
                          <>
                            <div className="row align-items-center">
                              {customDataDeal?.map((form, index) => (
                                <div
                                  className="col-lg-4 mb-3"
                                  key={form?._id || index}
                                >
                                  <div
                                    class="box-custom red-border "
                                    className={
                                      form[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",

                                      backgroundImage: form?.image
                                        ? `url(${URL.createObjectURL(
                                          form?.image
                                        )})`
                                        : `url(${form?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <Countdown endDate={form.endDate} />

                                    <div class="badge-deal">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      {form?.lowestDiscount
                                        ? `${form?.lowestDiscount} off`
                                        : "0 % off"}
                                    </div>

                                    <h5
                                      className="title"
                                      style={{ textAlign: "left" }}
                                    >
                                      {form.title ? form.title : "Title"}
                                    </h5>

                                    <h4
                                      style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "32px",
                                        lineHeight: "36px",
                                      }}
                                      // style={{ textAlign: "left" }}
                                      className="subtitle"
                                    >
                                      {form.description
                                        ? form.description
                                        : "Description"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center deal-footer">
                                        <div
                                          className="logo"
                                          style={{ background: "inherit" }}
                                        >
                                          <img
                                            src={form?.logo || noImage}
                                            alt="logo"
                                            className="rounded-circle"
                                          />
                                        </div>

                                        <div>
                                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                                          <h3
                                            style={{
                                              fontSize: "12px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {form?.label
                                              ? form?.label
                                              : "Select"}
                                          </h3>
                                          <p style={{ fontSize: "10px" }}>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index, form?._id)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                    {form?._id && (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          EditModelCustom(form, index)
                                        }
                                        className="btn-primary-edit"
                                      >
                                        Edit
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelVoucher}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "activity" && (
                          <>
                            <div className="row align-items-center">
                              {activityPreview?.map((form, index) => (
                                <div
                                  className="col-lg-4 mb-3"
                                  key={form?._id || index}
                                >
                                  <div
                                    class="box-custom red-border "
                                    className={
                                      form[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      backgroundImage: `url(${form?.logo})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    {/* <Countdown endDate={form.endDate} /> */}

                                    <div class="badge-deal">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      {form?.discount
                                        ? `${form?.discount} off`
                                        : "0 % off"}
                                    </div>

                                    <h5
                                      className="title"
                                      style={{
                                        textAlign: "left",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {form.title ? form.title : "Title"}
                                    </h5>

                                    <h4
                                      style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "32px",
                                        lineHeight: "36px",
                                      }}
                                    // style={{ textAlign: "left" }}
                                    // className="subtitle"
                                    >
                                      {form.description
                                        ? form.description
                                        : "Description"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center deal-footer">
                                        <div
                                          className="logo"
                                          style={{ background: "inherit" }}
                                        >
                                          <img
                                            src={form?.logo || noImage}
                                            alt="logo"
                                            className="rounded-circle"
                                          />
                                        </div>

                                        <div>
                                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                                          <h3 style={{ fontSize: "12px" }}>
                                            {form?.label
                                              ? form?.label
                                              : "Select"}
                                          </h3>
                                          <p style={{ fontSize: "10px" }}>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveActivityPreview(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelActivity}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                  Activity Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "event" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Event Selection
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("isAuto", e.target.value)
                                }
                                class="form-select"
                                value={homeData?.isAuto || "0"}
                              >
                                <option value="1">Automatic</option>
                                <option value="0">Manual</option>
                              </select>
                            </div>
                          </div>
                        )}
                        {homeData?.type == "event" &&
                          homeData?.isAuto == "1" && (
                            <>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Category
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "categoryForAuto",
                                        e.target.value
                                      )
                                    }
                                    class="form-select"
                                  >
                                    <option value="recent">Recent</option>
                                    <option value="upcoming">Upcoming</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Do you want to promote any event
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "promoteEvent",
                                        e.target.value
                                      )
                                    }
                                    defaultValue={
                                      homeData?.isAuto &&
                                        homeData?.events.length > 0
                                        ? "1"
                                        : "0"
                                    }
                                    class="form-select"
                                  >
                                    <option value="1">Yes</option>
                                    <option value="0" selected>No</option>
                                  </select>
                                </div>
                              </div>
                              {homeData?.promoteEvent === "1" && (
                                <>
                                  <div className="row align-items-center">
                                    {eventPreview?.map((form, index) => (
                                      <div
                                        className="col-lg-4 mb-3"
                                        key={form?._id || index}
                                      >
                                        <div
                                          class="box-custom red-border "
                                          className={
                                            form[index]
                                              ? "box-custom bgcard"
                                              : "box-custom"
                                          }
                                          style={{
                                            border: "1px solid #ccc",
                                            backgroundImage: `url(${form?.cover})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                          }}
                                        >
                                          {/* <Countdown endDate={form.endDate} /> */}

                                          <div class="badge-deal">
                                            {/* <img src={icon1} alt="icon" /> */}
                                            {form?.discount
                                              ? `${form?.discount} off`
                                              : "0 % off"}
                                          </div>

                                          <h5
                                            className="title"
                                            style={{
                                              textAlign: "left",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {form.title ? form.title : "Title"}
                                          </h5>

                                          <h4
                                            style={{
                                              fontFamily: "Montserrat",
                                              fontSize: "32px",
                                              lineHeight: "36px",
                                            }}
                                          // style={{ textAlign: "left" }}
                                          // className="subtitle"
                                          >
                                            {form.description
                                              ? form.description
                                              : "Description"}
                                          </h4>

                                          <div className="bottom-footer">
                                            <div className="d-flex align-items-center deal-footer">
                                              <div
                                                className="logo"
                                                style={{ background: "inherit" }}
                                              >
                                                <img
                                                  src={form?.logo || noImage}
                                                  alt="logo"
                                                  className="rounded-circle"
                                                />
                                              </div>

                                              <div>
                                                {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                                {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                                                <h3 style={{ fontSize: "12px" }}>
                                                  {form?.label
                                                    ? form?.label
                                                    : "Select"}
                                                </h3>
                                                <p style={{ fontSize: "10px" }}>
                                                  {form?.address
                                                    ? form?.address
                                                    : "Address"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div style={{ textAlign: "start" }}>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleRemoveEventPreview(index)
                                            }
                                            className="btn-remove"
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="col-lg-4">
                                      <button
                                        type="button"
                                        onClick={toggleModelEvent}
                                        className="modal-custom-button mt-3 mb-3"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>
                                      <Button
                                    onClick={DragDropTable}
                                    className="btn btn-info"
                                    style={{ marginLeft: "2rem" }}
                                  >
                                    <i className="fas fa-grip-vertical"></i>{" "}
                                    Event Order
                                  </Button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                        {homeData?.type == "event" &&
                          homeData?.isAuto == "0" && (
                            <>
                              <div className="row align-items-center">
                                {eventPreview?.map((form, index) => (
                                  <div
                                    className="col-lg-4 mb-3"
                                    key={form?._id || index}
                                  >
                                    <div
                                      class="box-custom red-border "
                                      className={
                                        form[index]
                                          ? "box-custom bgcard"
                                          : "box-custom"
                                      }
                                      style={{
                                        border: "1px solid #ccc",
                                        backgroundImage: `url(${form?.cover})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                      }}
                                    >
                                      {/* <Countdown endDate={form.endDate} /> */}

                                      <div class="badge-deal">
                                        {/* <img src={icon1} alt="icon" /> */}
                                        {form?.discount
                                          ? `${form?.discount} off`
                                          : "0 % off"}
                                      </div>

                                      <h5
                                        className="title"
                                        style={{
                                          textAlign: "left",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {form.title ? form.title : "Title"}
                                      </h5>

                                      <h4
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "32px",
                                          lineHeight: "36px",
                                        }}
                                      // style={{ textAlign: "left" }}
                                      // className="subtitle"
                                      >
                                        {form.description
                                          ? form.description
                                          : "Description"}
                                      </h4>

                                      <div className="bottom-footer">
                                        <div className="d-flex align-items-center deal-footer">
                                          <div
                                            className="logo"
                                            style={{ background: "inherit" }}
                                          >
                                            <img
                                              src={form?.logo || noImage}
                                              alt="logo"
                                              className="rounded-circle"
                                            />
                                          </div>

                                          <div>
                                            {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                            {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                                            <h3 style={{ fontSize: "12px" }}>
                                              {form?.label
                                                ? form?.label
                                                : "Select"}
                                            </h3>
                                            <p style={{ fontSize: "10px" }}>
                                              {form?.address
                                                ? form?.address
                                                : "Address"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ textAlign: "start" }}>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveEventPreview(index)
                                        }
                                        className="btn-remove"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    onClick={toggleModelEvent}
                                    className="modal-custom-button mt-3 mb-3"
                                  >
                                    <i class="fas fa-plus"></i>
                                  </button>
                                  <Button
                                    onClick={DragDropTable}
                                    className="btn btn-info"
                                    style={{ marginLeft: "2rem" }}
                                  >
                                    <i className="fas fa-grip-vertical"></i>{" "}
                                    Event Order
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}

                        {homeData?.type == "custom-venue" && (
                          <>
                            <div className="row align-items-center">
                              {customDataVenue?.map((item, index) => (
                                <div
                                  className="col-lg-4 mb-3"
                                  key={item?._id || index}
                                >
                                  <div
                                    className={
                                      item?.cover || item?.cover
                                        ? "col-lg-5 bgcard box-custom text-start ht-480"
                                        : "ht-480 col-lg-5 box-custom text-start"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      width: "100%",
                                      backgroundImage: `url(${item?.cover ||
                                        customDataVenue[index]?.cover
                                        })`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div class="badge">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      <input
                                        type="text"
                                        value={item.badge}
                                        autoComplete="off"
                                        readOnly
                                      />
                                    </div>
                                    <h5 className="title">
                                      {item.title ? item.title : "Title"}
                                    </h5>
                                    <h4 className="subtitle">
                                      {item.subTitle
                                        ? item.subTitle
                                        : "Sub Title"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="mb-3">
                                        <h6 className="fnt-18">
                                          {item.description
                                            ? item.description
                                            : "Description"}
                                        </h6>
                                        <p>{item.info ? item.info : "Info"}</p>
                                      </div>
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={
                                              item?.logo ||
                                              item[index]?.logo ||
                                              noImage
                                            }
                                            width="60"
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {" "}
                                            {item?.label ||
                                              item.forSelect?.label ||
                                              "Select Venue"}
                                          </h3>
                                          <p>
                                            {item?.address
                                              ? item?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        EditModelCustom(item, index)
                                      }
                                      className="btn-primary-edit"
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelCustom}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                  Custom Venue Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "custom-offer" && (
                          <>
                            <div className="row align-items-center">
                              {customDataOffer?.map((item, index) => (
                                <div
                                  className="col-lg-4 mb-3"
                                  key={item?._id || index}
                                >
                                  <div
                                    className={
                                      item?.cover || item?.cover
                                        ? "col-lg-5 ht-480 bgcard box-custom text-start"
                                        : "ht-480 col-lg-5 box-custom text-start"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      width: "100%",
                                      backgroundImage: `url(${item?.cover || item[index]?.cover
                                        })`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div class="badge">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      <input
                                        type="text"
                                        value={item.badge}
                                        autoComplete="off"
                                        readOnly
                                      />
                                    </div>
                                    <h5 className="title">
                                      {item.title ? item.title : "Title"}
                                    </h5>
                                    <h4 className="subtitle">
                                      {item.subTitle
                                        ? item.subTitle
                                        : "Sub Title"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="mb-3">
                                        <h6 className="fnt-18">
                                          {item.description
                                            ? item.description
                                            : "Description"}
                                        </h6>
                                        <p>{item.info ? item.info : "Info"}</p>
                                      </div>
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={
                                              item?.logo ||
                                              item[index]?.logo ||
                                              noImage
                                            }
                                            width="60"
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {" "}
                                            {item?.label ||
                                              item.forSelect?.label ||
                                              "Select Offer"}
                                          </h3>
                                          <p>
                                            {item?.address
                                              ? item?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        EditModelCustom(item, index)
                                      }
                                      className="btn-primary-edit"
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelCustom}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                  Custom offer Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {/* new changes custom-components*/}
                        {homeData?.type == "custom-components" && (
                          <>
                            <div className="row align-items-center">
                              {customDataComponent?.map((item, index) => (
                                <div
                                  className="col-lg-4 mb-3"
                                  key={item?._id || index}
                                >
                                  <div
                                    class="box-custom"
                                    className={
                                      customdata[index]
                                        ? "box-custom bgcard fix-heights"
                                        : "box-custom fix-heights"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      textAlign: "start",
                                      backgroundImage: `url(${item?.image || item.image
                                        })`,
                                      // backgroundImage: `url(${customdata[index]?.covercustom})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div class="badge">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      <input
                                        type="text"
                                        value={item.badge}
                                        autoComplete="off"
                                        readOnly
                                      />
                                    </div>
                                    <div className="bottom-footer mt-0">
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={
                                              item?.logo ||
                                              item[index]?.logo ||
                                              noImage
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {item?.label ||
                                              item.forSelect?.label ||
                                              "Select Offer"}
                                          </h3>
                                          <p>{item?.address}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dic">
                                      <div className="mb-3">
                                        <h6 className="fnt-18">
                                          {item.description
                                            ? item.description
                                            : "Description"}
                                        </h6>
                                      </div>
                                      <h5 className="title">
                                        {item.title ? item.title : "Title"}
                                      </h5>{" "}
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              {/* </> : null}  */}
                              <div className="col-lg-4">
                                {/* <button
                                  type="button"
                                  onClick={CustomMOdalComponenet}
                                  className="modal-custom-button mt-3 mb-3">
                                  <i class="fas fa-plus"></i>
                                </button> */}
                              </div>
                            </div>
                          </>
                        )}

                        {/* // <div className="row mb-4">
                          //   <label className="col-sm-3 col-form-label">
                          //     Select Custom Component
                          //   </label>
                          //   <div className="col-sm-6">
                          //     <MultiSelect
                          //       options={componentList}
                          //       onSelect={msUpdateComponent}
                          //       onRemove={msUpdateComponent}
                          //       selectedValues={defaultComponent}
                          //       placeholder="Select Custom Component"
                          //     />
                          //   </div>
                          // </div> */}

                        {homeData?.type == "video" && (
                          <>
                            <label className="col-sm-1 col-form-label">
                              Add Video
                            </label>
                            <div className="row g-2 mb-4">
                              {/* <div className="col-sm-6">
                            <MultiSelect
                              options={videoList}
                              onSelect={msUpdateVideo}
                              selectedValues={[]}
                              placeholder="Select Video"
                            />
                          </div> */}

                              <>
                                {uploadedVideo?.map((item, index) => (
                                  <div
                                    className="col-lg-3"
                                    key={item?._id || index}
                                  >
                                    <video width="240" height="200" controls>
                                      <source
                                        src={item?.videoUrl || item?.video}
                                        type="video/mp4"
                                      />
                                    </video>

                                    <button
                                      type="button"
                                      onClick={() => handleRemoveVideo(index)}
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                ))}
                              </>

                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  //  onClick={CustomVideoModal}
                                  onClick={() => setVideoMdl(true)}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i> Video
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Venue Selection
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("isAuto", e.target.value)
                                }
                                class="form-select"
                                value={homeData?.isAuto || "0"}
                              >
                                <option value="1">Automatic</option>
                                <option value="0">Manual</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "venue" &&
                          homeData?.isAuto == "1" && (
                            <>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Theme
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={themeList}
                                    onSelect={msUpdateTheme}
                                    onRemove={msUpdateTheme}
                                    selectedValues={defaultTheme}
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Cuisine
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={cuisineList}
                                    onSelect={msUpdateCuisine}
                                    onRemove={msUpdateCuisine}
                                    selectedValues={defaultCuisine}
                                  />
                                </div>
                              </div>

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Music
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={musicList}
                                    onSelect={msUpdateMusic}
                                    onRemove={msUpdateMusic}
                                    selectedValues={defaultMusic}
                                  />
                                </div>
                              </div>

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Feature
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={featureList}
                                    onSelect={msUpdateFeature}
                                    onRemove={msUpdateFeature}
                                    selectedValues={defaultFeature}
                                    placeholder="Search Feature"
                                  />
                                </div>
                              </div>

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Do you want to promote any venue
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "promoteVenue",
                                        e.target.value
                                      )
                                    }
                                    defaultValue={
                                      homeData?.isAuto &&
                                        homeData?.venues.length > 0
                                        ? "1"
                                        : "0"
                                    }
                                    class="form-select"
                                  >
                                    <option>Please Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                  </select>
                                </div>
                              </div>
                              {homeData?.promoteVenue === "1" && (
                                <>
                                  <div className="row mb-4 align-items-center">
                                    {venueData?.map((item, index) => (
                                      <div
                                        className="col-lg-4 mb-3"
                                        key={item?._id || index}
                                      >
                                        <div
                                          class="box-custom"
                                          className={
                                            item
                                              ? "box-custom bgcard fix-height"
                                              : "box-custom fix-height"
                                          }
                                          style={{
                                            border: "1px solid #ccc",
                                            textAlign: "start",
                                            backgroundImage: `url(${item?.cover})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                          }}
                                        >
                                          <div className="bottom-footer">
                                            <div className="d-flex align-items-center mb-3">
                                              <div className="logo">
                                                <img
                                                  src={item?.logo || noImage}
                                                  alt="logo"
                                                />
                                              </div>
                                              <div>
                                                <h3>
                                                  {item?.name
                                                    ? item?.name
                                                    : "Select Venue"}
                                                </h3>
                                                <p>
                                                  {item?.address
                                                    ? item?.address
                                                    : "Address"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ textAlign: "start" }}>
                                          <button
                                            onClick={() =>
                                              handleRemovevenuPreview(index)
                                            }
                                            className="btn-remove"
                                            type="button"
                                          >
                                            Remove
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              EdittoggleModelVenue(index)
                                            }
                                            className="btn-primary-edit"
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </div>
                                    ))}

                                    <div className="col-lg-4">
                                      <button
                                        type="button"
                                        onClick={toggleModel}
                                        className="modal-custom-button"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>

                                      <Button
                                        onClick={DragDropTable}
                                        className="btn btn-info"
                                        style={{ marginLeft: "2rem" }}
                                      >
                                        <i className="fas fa-grip-vertical"></i>{" "}
                                        venue
                                      </Button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                          { homeData?.type == "days" ? (
                            <DaysEditor providedDays={homeData?.days} updatedDays={ (data) => setDaysValue(data) } />
                          ) : null }

                        {homeData?.type == "deal" ? (
                          <div className="row mb-4 align-items-center">
                            {selectedDeals?.map((item, index) => (
                              <div class="col-md-3" key={item?._id || index}>
                                <div
                                  class="box-custom red-border "
                                  className={
                                    item ? "box-custom bgcard" : "box-custom"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    backgroundImage: item?.image
                                      ? `url(${item?.image})`
                                      : `url(${item?.image})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <Countdown
                                    endDate={`${item.endDate} ${item.endTime}`}
                                  />

                                  <div class="badge-deal">
                                    {item?.actualPrice ? (
                                      <>
                                        <del>{item.actualPrice}</del>{" "}
                                        {item.discountedPrice} AED
                                      </>
                                    ) : (
                                      "0 % off"
                                    )}
                                  </div>

                                  <h2 className="title">
                                    {item?.title ? item?.title : "Title"}
                                  </h2>

                                  <h5
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "16px",
                                      lineHeight: "20px",
                                    }}
                                    className="subtitle"
                                  >
                                    {item?.description
                                      ? item?.description
                                      : "Description"}
                                  </h5>

                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center deal-footer">
                                      <div
                                        className="logo"
                                        style={{ background: "inherit" }}
                                      >
                                        <img
                                          src={
                                            item?.venueLogo || item?.venueLogo
                                          }
                                          alt="logo"
                                          className="rounded-circle"
                                          height={50}
                                          width={50}
                                        />
                                      </div>

                                      <div>
                                        {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                        <h3>
                                          {item?.venueName
                                            ? item?.venueName
                                            : "Venue Name"}
                                        </h3>
                                        {/* <h3 style={{ fontSize: "12px" }}>
                                              {coverLogo[index]?.label
                                                ? coverLogo[index]?.label
                                                : "Select"}
                                            </h3> */}
                                        <p style={{ fontSize: "10px" }}>
                                          {item?.venueAddress
                                            ? item?.venueAddress
                                            : "Venue Address"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() => popSelectedDeal(index)}
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}

                        {homeData?.type == "venue" &&
                          homeData?.isAuto == "0" && (
                            <div className="row mb-4 align-items-center">
                              {venueData?.map((item, index) => (
                                <div
                                  className="col-lg-4 mb-3"
                                  key={item?._id || index}
                                >
                                  <div
                                    class="box-custom"
                                    className={
                                      item
                                        ? "box-custom bgcard fix-height"
                                        : "box-custom fix-height"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      textAlign: "start",
                                      backgroundImage: `url(${item?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={item?.logo || noImage}
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {item?.name
                                              ? item?.name
                                              : "Select Venue"}
                                          </h3>
                                          <p>
                                            {item?.address
                                              ? item?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      onClick={() =>
                                        handleRemovevenuPreview(index)
                                      }
                                      className="btn-remove"
                                      type="button"
                                    >
                                      Remove
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        EdittoggleModelVenue(index)
                                      }
                                      className="btn-primary-edit"
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              ))}

                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModel}
                                  className="modal-custom-button"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i> venue
                                </Button>
                              </div>
                            </div>
                          )}

                        {homeData?.type == "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Offer Selection
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("isAuto", e.target.value)
                                }
                                class="form-select"
                                value={homeData?.isAuto}
                              >
                                <option value={1}>Automatic</option>
                                <option value={0}>Manual</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "offer" &&
                          homeData?.isAuto == "0" && (
                            <div className="row mb-4 align-items-center">
                              {offerData?.map((item, index) => (
                                <div
                                  className="col-lg-4 mb-3"
                                  key={item?._id || index}
                                >
                                  <div
                                    class="box-custom"
                                    className={
                                      item
                                        ? "box-custom bgcard fix-heights"
                                        : "box-custom fix-heights"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      textAlign: "start",
                                      backgroundImage: `url(${item?.image})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div className="bottom-footer mt-0">
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={item.venue?.logo || noImage}
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {item.venue?.name
                                              ? item.venue?.name
                                              : "Select Offer"}
                                          </h3>
                                          <p>
                                            {item.venue?.address
                                              ? item.venue?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dic">
                                      <p className="mb-0">
                                        {item?.title ? item?.title : "Title"}
                                      </p>
                                      <h3 className="limit-text">
                                        {item?.description
                                          ? item?.description
                                          : "Description"}
                                      </h3>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      onClick={() =>
                                        handleRemoOfferPreview(index)
                                      }
                                      className="btn-remove"
                                      type="button"
                                    >
                                      Remove
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        EdittoggleModelOffer(index)
                                      }
                                      className="btn-primary-edit"
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              ))}

                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModel2}
                                  className="modal-custom-button"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i> Order
                                </Button>
                              </div>
                            </div>
                          )}
                        {homeData?.type == "offer" &&
                          homeData?.isAuto == "1" && (
                            <>
                              <div className="row mb-4 align-items-center">
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Category
                                  </label>
                                  <div className="col-sm-6">
                                    <MultiSelect
                                      options={categoryList}
                                      onSelect={msUpdateCategory}
                                      onRemove={msUpdateCategory}
                                      selectedValues={defaultCategory}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Do you want to promote any offer
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "promoteOffer",
                                        e.target.value
                                      )
                                    }
                                    defaultValue={
                                      homeData?.isAuto &&
                                        homeData?.offers.length > 0
                                        ? "1"
                                        : "0"
                                    }
                                    class="form-select"
                                  >
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                  </select>
                                </div>
                              </div>
                              {homeData?.promoteOffer === "1" && (
                                <>
                                  <div className="row mb-4 align-items-center">
                                    {offerData?.map((item, index) => (
                                      <div
                                        className="col-lg-4 mb-3"
                                        key={item?._id || index}
                                      >
                                        <div
                                          class="box-custom"
                                          className={
                                            item
                                              ? "box-custom bgcard fix-heights"
                                              : "box-custom fix-heights"
                                          }
                                          style={{
                                            border: "1px solid #ccc",
                                            textAlign: "start",
                                            backgroundImage: `url(${item?.image})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                          }}
                                        >
                                          <div className="bottom-footer mt-0">
                                            <div className="d-flex align-items-center mb-3">
                                              <div className="logo">
                                                <img
                                                  src={
                                                    item.venue?.logo || noImage
                                                  }
                                                  alt="logo"
                                                />
                                              </div>
                                              <div>
                                                <h3>
                                                  {item.venue?.name
                                                    ? item.venue?.name
                                                    : "Select Offer"}
                                                </h3>
                                                <p>
                                                  {item.venue?.address
                                                    ? item.venue?.address
                                                    : "Address"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="dic">
                                            <p className="mb-0">
                                              {item?.title
                                                ? item?.title
                                                : "Title"}
                                            </p>
                                            <h3 className="limit-text">
                                              {item?.description
                                                ? item?.description
                                                : "Description"}
                                            </h3>
                                          </div>
                                        </div>
                                        <div style={{ textAlign: "start" }}>
                                          <button
                                            onClick={() =>
                                              handleRemoOfferPreview(index)
                                            }
                                            className="btn-remove"
                                            type="button"
                                          >
                                            Remove
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              EdittoggleModelOffer(index)
                                            }
                                            className="btn-primary-edit"
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </div>
                                    ))}

                                    <div className="col-lg-4">
                                      <button
                                        type="button"
                                        onClick={toggleModel2}
                                        className="modal-custom-button"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />

      <ActivityModal
        show={modelActivity}
        closeModal={closeModal3}
        selectRow={selectActivityRow}
      />
      <EventModal
        show={modelEvent}
        closeModal={closeModal4}
        selectRow={selectEventRow}
      />

      <OfferModal
        show={model2}
        closeModal={closeModal2}
        selectRow={selectRow2}
        multipleSelect={selectRow2Multiple}
      />

      <Modal
        isOpen={modelcustom}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {homeData.type == "custom-venue" ? <>Custom Venue</> : null}
            {homeData.type == "custom-offer" ? <>Custom Offer</> : null}
          </h5>
          <button
            onClick={() => {
              closeModalCustom();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={CustomFormValue}>
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="row relativerow justify-content-center">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="title"
                        autoComplete="off"
                        placeholder="Title"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.title || ""}
                        required="required"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <textarea
                        value={currentForm.subTitle || ""}
                        type="textarea"
                        autoComplete="off"
                        name="subTitle"
                        placeholder="Sub Title"
                        className="form-control"
                        required="required"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        autoComplete="off"
                        value={currentForm.description || ""}
                        name="description"
                        className="form-control"
                        id="horizontal-password-input"
                        required="required"
                        placeholder="Description"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        name="info"
                        value={currentForm.info || ""}
                        autoComplete="off"
                        placeholder="Info"
                        className="form-control"
                        required="required"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="badge"
                        value={currentForm.badge || ""}
                        className="form-control"
                        required="required"
                        autoComplete="off"
                        placeholder="Badge"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-sm-12 ">
                      {homeData.type == "custom-venue" ? (
                        <>
                          <Select
                            name={`${idName}`}
                            options={venueoptions}
                            placeholder="Select Venue"
                            onChange={(e) => handleCoverChange(e, index)}
                            defaultValue={typeCustomvalue}
                          />
                        </>
                      ) : null}

                      {homeData.type == "custom-offer" ? (
                        <>
                          <Select
                            name={`${idName}`}
                            options={offeroptions}
                            placeholder="Select Offer"
                            onChange={(e) => handleCoverChange(e, index)}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    class="box-custom"
                    className={
                      currentForm ? "box-custom bgcard " : "box-custom"
                    }
                    style={{
                      border: "1px solid #ccc",
                      backgroundImage: `url(${currentForm?.cover})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div class="badge">
                      <input
                        type="text"
                        value={currentForm.badge}
                        autoComplete="off"
                        readOnly
                      />
                    </div>
                    <h5 className="title">
                      {currentForm.title ? currentForm.title : "Title"}
                    </h5>
                    <h4 className="subtitle">
                      {currentForm.subTitle
                        ? currentForm.subTitle
                        : "Sub Title"}
                    </h4>

                    <div className="bottom-footer">
                      <div className="mb-3">
                        <h6 className="fnt-18">
                          {currentForm.description
                            ? currentForm.description
                            : "Description"}
                        </h6>
                        <p>{currentForm.info ? currentForm.info : "Info"}</p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <div className="logo">
                          <img src={currentForm?.logo || noImage} alt="logo" />
                        </div>
                        <div>
                          <h3>
                            {currentForm?.label ||
                              currentForm?.forSelect?.label ||
                              "Select"}
                          </h3>
                          <p>
                            {currentForm?.address
                              ? currentForm?.address
                              : "Address"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn-primary btn btn-saves">
                    Save
                  </button>
                </div>
              </div>
            ))}
          </Form>
        </div>
      </Modal>

      <Modal
        isOpen={modelDeal}
        size="lg"
        className="modal-deal"
        style={{ minWidth: "80vw !important" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Add Deal
          </h5>
          <button
            onClick={() => {
              closeModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={CustomFormDeal}>
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="row relativerow justify-content-center">
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="title"
                        autoComplete="off"
                        placeholder="Title"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.title || ""}
                        required="required"
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        autoComplete="off"
                        value={currentForm.description || ""}
                        name="description"
                        className="form-control"
                        id="horizontal-password-input"
                        required="required"
                        placeholder="Description"
                        onChange={handleInputChanges}
                      />
                    </div>

                    <div className="col-sm-12 mb-4">
                      <Select
                        name={`${idName}`}
                        options={venueoptions}
                        placeholder="Select Venue"
                        onChange={(e) => handleCoverChange(e, index)}
                        defaultValue={typeCustomvalue}
                      />
                    </div>

                    <div className="col-sm-12 mb-4">
                      <Select
                        name={`category`}
                        options={categoryoptions.map((item) => ({
                          label: item.title,
                          value: item._id,
                        }))}
                        defaultValue={selectedCategory}
                        placeholder="Select Category"
                        onChange={(e) => updateCategory(e)}
                      />
                    </div>
                    <div className="col-sm-12 mb-4">
                      <input
                        type="file"
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                        name="cover"
                        className="form-control"
                        id="horizontal-password-input"
                        required="required"
                      />
                      <br />
                      {previewImage && (
                        <img
                          src={previewImage}
                          onClick={() => showLightBox(previewImage)}
                          style={{ width: "50px", height: "50px" }}
                        />
                      )}
                    </div>

                    <div className="col-lg-6 mb-4">
                      <input
                        type="date"
                        name="startDate"
                        autoComplete="off"
                        placeholder="Start Date"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.startDate || ""}
                        required="required"
                      />
                    </div>

                    <div className="col-lg-6 mb-4">
                      <input
                        type="date"
                        name="endDate"
                        autoComplete="off"
                        placeholder="End Date"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.endDate || ""}
                        required="required"
                      />
                    </div>
                  </div>

                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardHeader className="d-flex justify-content-between">
                          <h4 className="card-title">Vouchers</h4>
                          <Button
                            onClick={addVoucher}
                            type="button"
                            color="primary"
                          >
                            Add Voucher
                          </Button>
                        </CardHeader>
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">Sr No.</th>
                                  <th scope="col">Title</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Actual Pricing</th>
                                  <th scope="col">
                                    Discount <br />
                                    (In Percentage)
                                  </th>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">End Date</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {vouchars.map((voucher, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="text"
                                          defaultValue={voucher.title}
                                          onChange={(e) =>
                                            handleInputChange(e, index, "title")
                                          }
                                        />
                                      ) : (
                                        voucher.title
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="text"
                                          value={voucher.description}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              index,
                                              "description"
                                            )
                                          }
                                        />
                                      ) : (
                                        voucher.description
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="number"
                                          defaultValue={voucher.originalPrice}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "originalPrice"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.originalPrice
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="text"
                                          defaultValue={voucher.discountValue}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "discountValue"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.discountValue
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="date"
                                          defaultValue={voucher.startDate}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "startDate"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.startDate
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="date"
                                          defaultValue={voucher.endDate}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "endDate"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.endDate
                                      )}
                                    </td>
                                    <td>
                                      {voucher.saved ? (
                                        <a
                                          onClick={() => deleteVoucher(index)}
                                          href="javascript:void(0)"
                                        >
                                          Delete
                                        </a>
                                      ) : (
                                        <div>
                                          <Button
                                            onClick={() => saveVoucher(index)}
                                          >
                                            Save
                                          </Button>
                                          <br />
                                          <Button
                                            onClick={() => deleteVoucher(index)}
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div className="col-lg-3">
                  <div
                    class="box-custom red-border "
                    className={
                      coverLogo[index] ? "box-custom bgcard" : "box-custom"
                    }
                    style={{
                      border: "1px solid #ccc",
                      backgroundImage: selectedImage
                        ? `url(${URL.createObjectURL(selectedImage)})`
                        : `url(${coverLogo[index]?.cover})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <Countdown endDate={currentForm.endDate} />

                    <div class="badge-deal">
                      {/* <img src={icon1} alt="icon" /> */}
                      {lowestDiscount ? `${lowestDiscount} off` : "0 % off"}
                    </div>

                    <h5 className="title">
                      {currentForm.title ? currentForm.title : "Title"}
                    </h5>

                    <h4
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "32px",
                        lineHeight: "36px",
                      }}
                      className="subtitle"
                    >
                      {currentForm.description
                        ? currentForm.description
                        : "Description"}
                    </h4>

                    <div className="bottom-footer">
                      <div className="d-flex align-items-center deal-footer">
                        <div className="logo" style={{ background: "inherit" }}>
                          <img
                            src={coverLogo[index]?.logo || noImage}
                            alt="logo"
                            className="rounded-circle"
                          />
                        </div>

                        <div>
                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                          <h3 style={{ fontSize: "12px" }}>
                            {currentForm?.label ||
                              currentForm?.forSelect?.label ||
                              "Select"}
                          </h3>
                          <p style={{ fontSize: "10px" }}>
                            {currentForm?.address
                              ? currentForm?.address
                              : "Address"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="box-custom2 bgcard"
                    style={{
                      border: "1px solid #D6D6D6",
                      minHeight: "300px",
                      marginTop: "40px",
                      backgroundColor: "#D6D6D6 !important",
                    }}
                  >
                    {vouchars?.map((voucher, index) =>
                      voucher.saved || voucher?._id ? (
                        <div className="ticket" key={voucher?._id || index}>
                          <div className="section left">
                            <h2>{voucher.discountValue} Off</h2>
                          </div>
                          <div className="section right">
                            <p>{voucher.description}</p>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>

                <div className="text-center" style={{ margin: "10px" }}>
                  <button
                    disabled={!vouchars?.length}
                    type="submit"
                    className="btn-primary btn btn-saves "
                  >
                    Save
                  </button>
                </div>
              </div>
            ))}
          </Form>
        </div>
      </Modal>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <VideoModal
        show={videoMdl}
        AddUploadedVideo={AddUploadedVideo}
        closeModal={closeVideoModal}
      />

      <VoucherModal
        show={modelVoucher}
        closeModal={closeModalVoucher}
        selectRow={(deal) => pushSelectedDeal(deal)}
      />
      <FileUpload message="File Uploading" status={startLoader} />
      <DragDropModal
        show={showOrder}
        closeModal={() => setShowOrder(false)}
        data={dragDropData}
        setData={updateDragDropData}
      />
          <BannerModal
        show={bannerModal}
        closeModal={closeBannerModal}
        selectRow={selectBannerRow}
      />

      <PollModal
        show={pollModal}
        closeModal={closePollModal}
        selectRow={selectPollRow}
      />



      <CustomModal
        show={customModal}
        closeModal={closeCustomModal}
        selectRow={selectCustomRow}
      />

      <CustomImageModal
        show={customImageModal}
        closeModal={closeCustomImageModal}
        selectRow={selectCustomImageRow}
      />
    </React.Fragment>
  );
}
