import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button as ReactstrapButton,
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  postRequest,
  deleteRequest,
  deleteConfirmationWords,
} from "components/Common/Utils";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

// Material-UI components (for the dialog)
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "./CustomCard.css"; // Your custom CSS

const VenueReservationDetailPage = () => {
  const location = useLocation();
  const state = location.state?.row;

  const [venueData, setVenueData] = useState(state || {});
  const [loader, setLoader] = useState(false);

  // Lightbox
  const [openLightbox, setOpenLightBox] = useState(false);
  const [image, setImage] = useState("");

  // Dialog for replying to reviews
  const [open, setOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [textReply, setTextReply] = useState("");

  // Pagination
  const [currentFPage, setCurrentFPage] = useState(1);
  const [totalPage] = useState(0); // total records for pagination
  const [sizePerPageOrg] = useState(10);

  // URL Param
  const { id } = useParams();

  // Fetch booking/venue detail
  const fetchVenueBookingDetail = async () => {
    setLoader(true);
    try {
      const res = await postRequest("venue/table/booking-detail/admin", {
        bookingId: id,
      });
      if (res?.status === 1 && Array.isArray(res?.data) && res.data.length > 0) {
        setVenueData(res.data[0]);
      }
    } catch (err) {
      console.error(err);
      toast.error("Error fetching booking details.");
    }
    setLoader(false);
  };

  useEffect(() => {
    if (!state) {
      fetchVenueBookingDetail();
    } else {
      // In case you still want to refresh data even if state is available:
      fetchVenueBookingDetail();
    }
  }, []);

  // Lightbox handlers
  const showLightBox = (img) => {
    if (img) {
      setImage(img);
      setOpenLightBox(true);
    }
  };

  // Example function if you want to show a confirmation prompt
  const showRandomWordDialog = (recordId, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryFile(recordId, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryFile = async (recordId, file) => {
    try {
      const response = await deleteRequest(`venue/gallery/delete`, {
        venueId: recordId,
        file,
      });
      if (response.status) {
        Swal.fire("Deleted!", "Deleted successfully", "success");
        fetchVenueBookingDetail();
      } else {
        Swal.fire("Error!", "Something went wrong", "error");
      }
    } catch (err) {
      console.error(err);
      Swal.fire("Error!", "Something went wrong", "error");
    }
  };

  // Table columns (if still needed)
  const columns = [
    {
      dataField: "user.name",
      text: "User Name",
      sort: false,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "user.image",
      text: "User Image",
      formatter: (cell, row) => (
        <div className="table-image-cell">
          <img
            src={row?.user?.image || ""}
            alt="User"
            width="50px"
            style={{ borderRadius: "20%" }}
          />
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "user.email",
      text: "User Email",
      sort: false,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "noOfSeats",
      text: "No of Seats",
      sort: false,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "date",
      text: "Booking Date",
      formatter: (cell) => (cell ? moment(cell).format("DD-MM-YYYY") : ""),
      sort: false,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "startTime",
      text: "Start Time",
      sort: false,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "endTime",
      text: "End Time",
      sort: false,
      headerAlign: "center",
      align: "center",
    },
  ];

  const tableData = venueData ? [venueData] : [];

  // Offer & Package Info
  const offerDetails = venueData?.order?.orderDetails || [];

  // Check if there's a promo code used
  const hasPromoCode =
    venueData?.voucherId !== null && venueData?.voucherId !== undefined;

  // Handlers for the dialog
  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateReply = async () => {
    // Example: if you had an API for updating replies
    // const response = await putRequest("rating/reply/update", {
    //   ratingId: selectedReview._id,
    //   reply: textReply,
    // });
    // if (response.status === 1) toast.success("Reply updated");
    // else toast.error(response.message);

    setOpen(false);
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "paid": return "success";
      case "pending": return "warning";
      case "confirmed": return "success";
      case "rejected": return "danger";
      default: return "secondary";
    }
  };

  // Helper to format the venue data if missing
  const venueName = venueData?.venue?.name || "N/A";
  const venueCover = venueData?.venue?.cover || "";
  const venueLogo = venueData?.venue?.logo || "";
  const aboutVenue = venueData?.venue?.about || "No description";
  const venueEmail = venueData?.venue?.email || "N/A";
  const venuePhone = venueData?.venue?.phone || "N/A";

  const bookingStatus = venueData?.bookingStatus || "N/A";
  const paymentStatus = venueData?.paymentStatus || "unpaid";

   const user = venueData?.user || {};

  // If you want to color-code payment or booking statuses:
  const getPaymentBadgeColor = () => {
    if (paymentStatus === "paid") return "success";
    if (paymentStatus === "pending") return "warning";
    return "secondary";
  };

  const getBookingBadgeColor = () => {
    if (bookingStatus === "confirmed") return "success";
    if (bookingStatus === "rejected" || bookingStatus === "cancelled")
      return "danger";
    if (bookingStatus === "pending") return "warning";
    return "dark";
  };

  // User Info
  const userFirstName = venueData?.user?.first_name || "";
  const userLastName = venueData?.user?.last_name || "";
  const userEmail = venueData?.user?.email || "";
  const userName = venueData?.user?.name || `${userFirstName} ${userLastName}`;
  const userImage = venueData?.user?.image || "";

  return (
    <>
      <ToastContainer />

      {/* Dialog for replying to reviews */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter your reply below.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={selectedReview?.reply?.reply || ""}
            onChange={(e) => setTextReply(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Submit Reply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Lightbox for images */}
      {openLightbox && (
        <Lightbox mainSrc={image} onCloseRequest={() => setOpenLightBox(false)} />
      )}

      {/* PAGE TITLE & BACK BUTTON */}
      <Row className="mb-2">
        <Col md="10">
          <div className="page-title-box">
            <h4 className="mb-0">Venue Reservation Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">Venue Reservation</li>
              <li className="breadcrumb-item active">Reservation Details</li>
            </ol>
          </div>
        </Col>
        <Col md="2" className="text-end">
          <ReactstrapButton
            color="primary"
            size="lg"
            onClick={() => window.history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </ReactstrapButton>
        </Col>
      </Row>

      {/* LOADER */}
      {loader ? (
        <CardBody style={{ height: "100px" }}>
          <Spinner
            color="info"
            style={{
              height: "4rem",
              width: "4rem",
              position: "absolute",
              left: "50%",
            }}
          >
            Loading...
          </Spinner>
        </CardBody>
      ) : (
        <>
          <Row>
            {/* LEFT COLUMN: Venue Info */}
            <Col md="6">
              <Card className="mb-4">
                <div
                  className="cover-image"
                  style={{
                    height: "200px",
                    background: `url(${venueCover}) center/cover no-repeat`,
                    cursor: venueCover ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (venueCover) showLightBox(venueCover);
                  }}
                />
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "3px solid #fff",
                      position: "absolute",
                      top: "-50px",
                      left: "25px",
                      cursor: venueLogo ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (venueLogo) showLightBox(venueLogo);
                    }}
                  >
                    <img
                      src={venueLogo}
                      alt="Logo"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                </div>
                <CardBody style={{ marginTop: "50px" }}>
                  <h5 className="mb-3">
                    <strong>{venueName}</strong>
                  </h5>
                  <Row>
                    <Col xs="12" sm="6" className="mb-2">
                      <p className="mb-1">
                        <strong>Email:</strong>
                      </p>
                      <p>{venueEmail}</p>
                    </Col>
                    <Col xs="12" sm="6" className="mb-2">
                      <p className="mb-1">
                        <strong>Phone:</strong>
                      </p>
                      <p>{venuePhone}</p>
                    </Col>
                  </Row>
                  <div className="mb-2">
                    <Badge color={getBookingBadgeColor()} className="me-2">
                      {bookingStatus}
                    </Badge>
                    <Badge color={getPaymentBadgeColor()} className="me-2">
                      {paymentStatus}
                    </Badge>
                    {hasPromoCode && (
                      <Badge color="info" className="me-2">
                        Promo Applied
                      </Badge>
                    )}
                  </div>
                  <hr />
                  <p>
                    <strong>About: </strong>
                    {aboutVenue}
                  </p>
                </CardBody>
              </Card>
            </Col>

            {/* RIGHT COLUMN: Booking Details & User Info */}
            <Col md="6">
              {/* Booking Details Card */}
              <Card className="mb-4">
                <CardBody>
                  <h5 className="mb-4">Booking Overview</h5>
                  <Row className="mb-2">
                    <Col xs="6">
                      <p className="mb-1">
                        <strong>Date:</strong>
                      </p>
                      <p>
                        {venueData?.date
                          ? moment(venueData.date).format("DD-MM-YYYY")
                          : "N/A"}
                      </p>
                    </Col>
                    <Col xs="6">
                      <p className="mb-1">
                        <strong>Time:</strong>
                      </p>
                      <p>
                        {venueData?.startTime} - {venueData?.endTime}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="6">
                      <p className="mb-1">
                        <strong>Seats:</strong>
                      </p>
                      <p>{venueData?.noOfSeats || "-"}</p>
                    </Col>
                    <Col xs="6">
                      <p className="mb-1">
                        <strong>Booking Code:</strong>
                      </p>
                      <p>{venueData?.bookingCode || "N/A"}</p>
                    </Col>
                  </Row>
                  <hr />
                  <h6>Table Reservation Status:</h6>
                  <Badge color={getBookingBadgeColor()}>
                    {venueData?.status || "unknown"}
                  </Badge>
                </CardBody>
              </Card>

              {/* User Info Card */}
              <Card className="mb-4">
                <CardBody>
                  <h5 className="mb-3">Guest Information</h5>
                  <Row>

                  <Col xs="3">
  <div
    style={{
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: userImage ? "transparent" : "#007bff", // Blue background for initials
      color: "white",
      fontSize: "18px",
      fontWeight: "bold",
      textTransform: "uppercase",
      cursor: userImage ? "pointer" : "default",
    }}
    onClick={() => {
      if (userImage) showLightBox(userImage);
    }}
  >
    {userImage ? (
      <img
        src={userImage}
        alt="User"
        style={{ width: "100%", height: "auto" }}
      />
    ) : (
      // Show initials if no image
      `${user.first_name?.charAt(0) || ""}${user.last_name?.charAt(0) || ""}`
    )}
  </div>
</Col>

                    
                    <Col xs="9">
                      <p className="mb-1">
                        <strong>{userName}</strong>
                      </p>
                      <p>{userEmail}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

           {/* BOOKING INFO TABLE (Optional) */}
           <Row className="mt-4">
            <Col md="12">
              <h4>Booking Details (Full)</h4>
              <Card className="p-3">
                <Row>
                  <Col md="4"><strong>User Name:</strong> {user.name}</Col>
                  <Col md="4"><strong>Email:</strong> {user.email}</Col>
                  <Col md="4"><strong>Seats:</strong> {venueData?.noOfSeats}</Col>
                </Row>
                <Row className="mt-2">
                  <Col md="4"><strong>Date:</strong> {moment(venueData?.date).format("DD-MM-YYYY")}</Col>
                  <Col md="4"><strong>Time:</strong> {venueData?.startTime} - {venueData?.endTime}</Col>
                  <Col md="4"><strong>Status:</strong> <Badge color={getBadgeColor(venueData?.status)}>{venueData?.status}</Badge></Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Special Offers (if any) */}
          {offerDetails.length > 0 && (
           <Row>
           <Col md="12">
             <h4 className="mt-3 mb-2">Offer / Special Offers And Packages</h4>
           </Col>
         
           {offerDetails.map((detail, index) => {
             const { offer, package: pkg, status, specialoffer } = detail;
             const isPurchased = status === "succeeded";
         
             return (
               <Col md="4" lg="3" key={detail._id || index}>
                 <Card className={`offer-card mb-4 ${isPurchased ? "highlight-offer" : ""}`}>
                   <CardBody>
                     <h5 className="offer-title">{offer?.title || "Special Offer"}</h5>
                     {offer?.description && <p className="offer-desc">{offer.description}</p>}
         
                     {offer?.image && (
                       <div
                         style={{
                           width: "100%",
                           height: "180px",
                           background: `url(${offer.image}) center/cover no-repeat`,
                           marginBottom: "1rem",
                           cursor: "pointer",
                         }}
                         onClick={() => showLightBox(offer.image)}
                       >
                         <center>
                           <img src={offer.image} alt="Offer" height={150} style={{ margin: "0 auto" }} />
                         </center>
                       </div>
                     )}
         
                     {/* Package Info */}
                     {pkg && (
                       <div className="package-info mt-2">
                         <p className="package-title">
                           <strong>Package:</strong> {pkg?.title}
                         </p>
                         {pkg?.amount && <p><strong>Amount:</strong> {pkg.amount}</p>}
                         {pkg?.discount && <p><strong>Discount:</strong> {pkg.discount}%</p>}
                       </div>
                     )}
         
                     {/* Special Offer Details - Dashed Border Section */}
                     {specialoffer && (
                       <div
                         style={{
                           border: "1px dashed #007bff",
                           padding: "10px",
                           marginTop: "15px",
                           borderRadius: "5px",
                         }}
                       >
                         <h6 className="text-primary">Special Offer Details</h6>
                         <p><strong>Title:</strong> {specialoffer.title || "N/A"}</p>
                         <p><strong>Description:</strong> {specialoffer.description || "N/A"}</p>
                         <p><strong>Type:</strong> {specialoffer.type || "N/A"}</p>
                         <p><strong>Discount Type:</strong> {specialoffer.discountType || "N/A"}</p>
                         <p><strong>Discount:</strong> {specialoffer.discount || "N/A"}%</p>
                       </div>
                     )}
         
                     {/* Status Badge */}
                     <div className="mt-3">
                       <strong>Status:</strong>{" "}
                       <Badge color={isPurchased ? "success" : "warning"}>
                         {status || "N/A"}
                       </Badge>
                     </div>
                   </CardBody>
                 </Card>
               </Col>
             );
           })}
         </Row>
         
          )}

         
        </>
      )}
    </>
  );
};

export default VenueReservationDetailPage;
