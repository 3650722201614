import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  customValidator,
} from "../../components/Common/Utils.js";

export default function CreateTheme() {
  const [themeData, setThemeData] = useState({ title: "" });
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  let history = useHistory();


  useEffect(() => {
    fetchTheme();
  }, []);

   // **Validation Function**
  const validateForm = () => {
    let tempErrors = {};
    if (!themeData.title) {
      tempErrors.title = "Title is required";
    }

    if (!themeData.country) {
      tempErrors.country = "Please select country";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const Createtheme = async (e) => {
    e.preventDefault();

    // if(!themeData.title){
    //   toast.error("Please enter theme title");
    //   return;
    // }

    // if(!themeData.country){
    //   toast.error("Please select country");
    //   return;
    // }    

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }
    

    setLoading(true);
    const dataObj = {     
      name: themeData.title,
      country: themeData.country,
    };

    const response = await postRequest("comman/region/add", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/region");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...themeData };
    list[index] = value;
    setThemeData(list);
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/country/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCountries(options);
    }
  };


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Region Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Region Management</li>:{" "}
              <li className="breadcrumb-item">Create Region</li>
            </ol>
          </div>
        </div>
        <div className="col-lg-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
        </div>
      </div>     
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Region </h4>
                <Form onSubmit={Createtheme}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                             {errors.title && <span className="text-danger">{errors.title}</span>}
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                         Country
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("country", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="">Select Country</option>
                            {countries.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                            
                          </select>
                          {errors.country && <span className="text-danger">{errors.country}</span>}
                        </div>
                      </div>


                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
