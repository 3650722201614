import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  Modal,
  Input,
  Label,
} from "reactstrap";
import ReactQuill from "react-quill";

const FormComponent = ({ addData, currentPolicyData, closeModal }) => {
  // State for Cancellation Policy Text
  console.log("policy-1", currentPolicyData);
  const [cancelPolicyText, setCancelPolicyText] = useState("");

  // New state for Time Unit (hour/day), default is "hour"
  const [timeUnit, setTimeUnit] = useState("hour");

  // State for Cancellation Policy Rules with updated keys: from & to
  const [cancelPolicyRules, setCancelPolicyRules] = useState([
    { from: "", to: "", type: "percentage", amount: "" },
  ]);

  // State for tracking validation errors
  const [errors, setErrors] = useState({});

  /**
   * Initialize form state based on currentPolicyData
   */
  useEffect(() => {
    if (currentPolicyData) {
      const { text, rules, unit } = currentPolicyData;

      setCancelPolicyText(text || "");

      // Set time unit if provided; otherwise default to "hour"
      setTimeUnit(unit || "hour");

      if (Array.isArray(rules) && rules.length > 0) {
        setCancelPolicyRules(
          rules.map((rule) => ({
            // In case existing rules still use startDays/endDays, fallback accordingly
            from: rule.from || rule.startDays || "",
            to: rule.to || rule.endDays || "",
            type: rule.type || "percentage",
            amount: rule.amount || "",
          }))
        );
      } else {
        setCancelPolicyRules([{ from: "", to: "", type: "percentage", amount: "" }]);
      }
    }
  }, [currentPolicyData]);

  /**
   * Handler to update Cancellation Policy Text
   */
  const handleCancelPolicyTextChange = (value) => {
    setCancelPolicyText(value);
  };

  /**
   * Handlers for Cancellation Policy Rules
   */
  const handleRuleChange = (index, field, value) => {
    const updatedRules = cancelPolicyRules.map((rule, i) =>
      i === index ? { ...rule, [field]: value } : rule
    );
    setCancelPolicyRules(updatedRules);
  };

  const addRule = () => {
    setCancelPolicyRules([
      ...cancelPolicyRules,
      { from: "", to: "", type: "percentage", amount: "" },
    ]);
  };

  const removeRule = (index) => {
    const updatedRules = cancelPolicyRules.filter((_, i) => i !== index);
    setCancelPolicyRules(updatedRules);
  };

  /**
   * Validation Function
   */
  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // Validate Cancellation Policy Text
    const plainText = new DOMParser()
      .parseFromString(cancelPolicyText, "text/html")
      .body.textContent || "";
    if (!plainText.trim()) {
      newErrors.cancelPolicyText = "Cancellation policy text is required.";
      valid = false;
    }

    // Validate Cancellation Policy Rules
    if (cancelPolicyRules.length === 0) {
      newErrors.cancelPolicyRules =
        "At least one cancellation policy rule is required.";
      valid = false;
    } else {
      cancelPolicyRules.forEach((rule, index) => {
        const { from, to, type, amount } = rule;
        if (from === "" || to === "" || type === "" || amount === "") {
          newErrors[`rule_${index}`] = "All fields in the rule are required.";
          valid = false;
        } else {
          if (isNaN(from) || isNaN(to)) {
            newErrors[`rule_${index}`] = "From and To must be numbers.";
            valid = false;
          } else {
            if (Number(from) < Number(to)) {
              newErrors[`rule_${index}`] =
                "From must be greater than or equal to To.";
              valid = false;
            }
            if (Number(from) < 0 || Number(to) < 0) {
              newErrors[`rule_${index}`] = "From and To cannot be negative.";
              valid = false;
            }
          }

          const allowedTypes = ["percentage", "fixed_deduction"];
          if (!allowedTypes.includes(type)) {
            newErrors[`rule_${index}`] =
              "Type must be either 'percentage' or 'fixed_deduction'.";
            valid = false;
          }

          if (isNaN(amount) || Number(amount) < 0) {
            newErrors[`rule_${index}`] =
              "Amount must be a non-negative number.";
            valid = false;
          }
        }
      });

      // Check for overlapping ranges
      const sortedRules = [...cancelPolicyRules].sort(
        (a, b) => Number(b.from) - Number(a.from)
      );
      for (let i = 0; i < sortedRules.length - 1; i++) {
        const currentRule = sortedRules[i];
        const nextRule = sortedRules[i + 1];
        if (Number(currentRule.to) < Number(nextRule.from)) {
          newErrors[`rule_${i}`] = `Rule ${i + 1} overlaps with Rule ${i + 2}.`;
          valid = false;
        }
      }
    }

    setErrors(newErrors);
    return valid;
  };

  /**
   * Handler for Form Submission
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Prepare the cancellation policy data
      const cancellationPolicy = {
        text: cancelPolicyText.trim(),
        unit: timeUnit, // include the selected unit
        rules: cancelPolicyRules.map((rule) => ({
          from: Number(rule.from),
          to: Number(rule.to),
          type: rule.type,
          amount: Number(rule.amount),
        })),
      };

      // Submit the data
      addData(cancellationPolicy);

      // Close the modal
      closeModal();

      // Optionally, reset the form fields if adding a new policy
      if (!currentPolicyData) {
        setCancelPolicyText("");
        setTimeUnit("hour");
        setCancelPolicyRules([{ from: "", to: "", type: "percentage", amount: "" }]);
      }

      // Clear errors
      setErrors({});
    } else {
      // Optionally, display a general error message here
    }
  };

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              {/* Cancellation Policy Text */}
              <div className="mb-4">
                <Label for="cancelPolicyText">Cancellation Policy</Label>
                <ReactQuill
                  style={{
                    height: "200px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  value={cancelPolicyText}
                  onChange={handleCancelPolicyTextChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link", "image", "video"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "link",
                    "image",
                    "video",
                  ]}
                />
                {errors.cancelPolicyText && (
                  <div className="text-danger">{errors.cancelPolicyText}</div>
                )}
              </div>

              {/* Select Unit (Hour/Day) */}
              <div className="mb-4" style={{ marginTop: 80 }}>
                <Label for="timeUnit">Select Unit</Label>
                <Input
                  type="select"
                  name="timeUnit"
                  id="timeUnit"
                  value={timeUnit}
                  onChange={(e) => setTimeUnit(e.target.value)}
                >
                  <option value="hour">Hour</option>
                  <option value="day">Day</option>
                </Input>
              </div>

              {/* Cancellation Policy Rules */}
              <div className="mb-4">
                <Label style={{ margin: 40 }}>Cancellation Policy Rules</Label>
                {cancelPolicyRules.map((rule, index) => (
                  <Row key={index} className="mb-3 align-items-end">
                    <Col md={3}>
                      <Label for={`from_${index}`}>From</Label>
                      <Input
                        type="number"
                        name={`from_${index}`}
                        id={`from_${index}`}
                        value={rule.from}
                        onChange={(e) =>
                          handleRuleChange(index, "from", e.target.value)
                        }
                        placeholder="e.g., 30"
                        min="0"
                      />
                    </Col>
                    <Col md={3}>
                      <Label for={`to_${index}`}>To</Label>
                      <Input
                        type="number"
                        name={`to_${index}`}
                        id={`to_${index}`}
                        value={rule.to}
                        onChange={(e) =>
                          handleRuleChange(index, "to", e.target.value)
                        }
                        placeholder="e.g., 10"
                        min="0"
                      />
                    </Col>
                    <Col md={3}>
                      <Label for={`type_${index}`}>Type</Label>
                      <Input
                        type="select"
                        name={`type_${index}`}
                        id={`type_${index}`}
                        value={rule.type}
                        onChange={(e) =>
                          handleRuleChange(index, "type", e.target.value)
                        }
                      >
                        <option value="percentage">Percentage</option>
                        <option value="fixed_deduction">Fixed Deduction</option>
                      </Input>
                    </Col>
                    <Col md={2}>
                      <Label for={`amount_${index}`}>Amount</Label>
                      <Input
                        type="number"
                        name={`amount_${index}`}
                        id={`amount_${index}`}
                        value={rule.amount || 0}
                        onChange={(e) =>
                          handleRuleChange(index, "amount", e.target.value)
                        }
                        placeholder="e.g., 50"
                        min="0"
                      />
                    </Col>
                    <Col md={1}>
                      <Button
                        color="danger"
                        onClick={() => removeRule(index)}
                        disabled={cancelPolicyRules.length === 1}
                      >
                        &times;
                      </Button>
                    </Col>
                    {errors[`rule_${index}`] && (
                      <Col md={12}>
                        <div className="text-danger">{errors[`rule_${index}`]}</div>
                      </Col>
                    )}
                  </Row>
                ))}
                <Button color="secondary" onClick={addRule}>
                  Add Rule
                </Button>
                {errors.cancelPolicyRules && (
                  <div className="text-danger mt-2">{errors.cancelPolicyRules}</div>
                )}
              </div>

              {/* Submit Button */}
              <div className="text-end">
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const PackageModal = ({ show, closeModal, addData, currentPolicyData }) => {
  console.log("policy-0", currentPolicyData);
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Cancellation Policy Info
        </h5>
        <button
          onClick={closeModal}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent
          addData={addData}
          currentPolicyData={currentPolicyData}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  );
};

export default PackageModal;
