import React, { useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SketchPicker } from "react-color";
import { postRequestForm } from "../../components/Common/Utils.js";
import { GripVertical } from "lucide-react";
import "./DaysEditor.css";

// Updated: Using _id instead of id
const initialDays = [
  { _id: "1", title: "Monday",    bgType: "color", bgColor: "#FFFFFF", bgImage: "", textColor: "#000000" },
  { _id: "2", title: "Tuesday",   bgType: "color", bgColor: "#33A1FF", bgImage: "", textColor: "#000000" },
  { _id: "3", title: "Wednesday", bgType: "color", bgColor: "#33FF57", bgImage: "", textColor: "#000000" },
  { _id: "4", title: "Thursday",  bgType: "color", bgColor: "#FF33A8", bgImage: "", textColor: "#000000" },
  { _id: "5", title: "Friday",    bgType: "color", bgColor: "#FFC733", bgImage: "", textColor: "#000000" },
  { _id: "6", title: "Saturday",  bgType: "color", bgColor: "#8E44AD", bgImage: "", textColor: "#000000" },
  { _id: "7", title: "Sunday",    bgType: "color", bgColor: "#16A085", bgImage: "", textColor: "#000000" },
];

// SortableItem component
function SortableItem({ day, onUpdate }) {
  // Use day._id as the `id` prop for @dnd-kit
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: day._id });

  // Inline style for transformation/animation
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging
      ? {
          zIndex: 999,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)",
        }
      : {}),
  };

  // Local UI state
  const [showInputs, setShowInputs] = useState(false);
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Refs used for detecting clicks outside color pickers
  const bgPickerRef = useRef(null);
  const textPickerRef = useRef(null);

  // Hide color pickers when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (bgPickerRef.current && !bgPickerRef.current.contains(event.target)) {
        setShowBgColorPicker(false);
      }
      if (textPickerRef.current && !textPickerRef.current.contains(event.target)) {
        setShowTextColorPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Upload handler via dropzone
  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        setIsUploading(true);
        const selectedImageRes = await postRequestForm(
          "comman/img/upload",
          formData
        );
        if (selectedImageRes?.data) {
          const uploadedUrl = selectedImageRes.data.url;
          // Update day with the new image URL
          onUpdate(day._id, { bgImage: uploadedUrl });
        }
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div ref={setNodeRef} className="day-card" style={style}>
      {/* HEADER: Drag icon left (commented out), Title right */}
      <div className="day-card-header">
        {/*
        <div className="drag-handle" {...attributes} {...listeners}>
          <GripVertical size={20} />
        </div>
        */}
        <h3 onClick={() => setShowInputs(!showInputs)} className="title-toggle">
          {day.title} {showInputs ? "▲" : "▼"}
        </h3>
      </div>

      {showInputs && (
        <div className="input-container">
          {/* Background Type Dropdown */}
          <div className="dropdown-container">
            <label>Background Type:</label>
            <select
              value={day.bgType}
              onChange={(e) => onUpdate(day._id, { bgType: e.target.value })}
            >
              <option value="color">Color</option>
              <option value="image">Image</option>
            </select>
          </div>

          {/* Background Color Picker */}
          {day.bgType === "color" && (
            <div className="color-picker-container">
              <label>Background Color:</label>
              <div className="color-picker-wrapper">
                <button
                  type="button"
                  className="color-box"
                  style={{ backgroundColor: day.bgColor }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowBgColorPicker(!showBgColorPicker);
                    setShowTextColorPicker(false);
                  }}
                />
                {showBgColorPicker && (
                  <div ref={bgPickerRef} className="color-picker-popup">
                    <SketchPicker
                      color={day.bgColor}
                      onChangeComplete={(color) =>
                        onUpdate(day._id, { bgColor: color.hex })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Background Image Upload (even if bgType is color, we allow upload) */}
          <div className="dropzone-container" {...getRootProps()}>
            <input {...getInputProps()} />
            {isUploading ? (
              <p>Uploading...</p>
            ) : day.bgImage ? (
              <img src={day.bgImage} alt="Image" className="uploaded-image" />
            ) : (
              <p>
                Drop or Click to Upload
                {day.bgType === "color" ? "" : " Background"} Image
              </p>
            )}
          </div>

          {/* Text Color Picker */}
          <div className="color-picker-container">
            <label>Font Color:</label>
            <div className="color-picker-wrapper">
              <button
                type="button"
                className="color-box"
                style={{ backgroundColor: day.textColor }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowTextColorPicker(!showTextColorPicker);
                  setShowBgColorPicker(false);
                }}
              />
              {showTextColorPicker && (
                <div ref={textPickerRef} className="color-picker-popup">
                  <SketchPicker
                    color={day.textColor}
                    onChangeComplete={(color) =>
                      onUpdate(day._id, { textColor: color.hex })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function DaysEditor({ providedDays, updatedDays }) {
  // If providedDays is passed in, use it, otherwise fall back to initialDays
  const [days, setDays] = useState(providedDays || initialDays);

  // Helper to update a single day’s props
  const updateDay = (_id, updates) => {
    setDays((prevDays) =>
      prevDays.map((day) =>
        day._id === _id ? { ...day, ...updates } : day
      )
    );
  };

  // Whenever days changes, notify parent via updatedDays
  useEffect(() => {
    updatedDays(days);
  }, [days]);

  // Drag & drop end handler
  const handleDragEnd = (event) => {
    const { active, over } = event;
    // If no target or the same item, do nothing
    if (!over || active.id === over.id) return;

    setDays((items) => {
      const oldIndex = items.findIndex((item) => item._id === active.id);
      const newIndex = items.findIndex((item) => item._id === over.id);
      return arrayMove(items, oldIndex, newIndex);
    });
  };

  return (
    <div className="days-editor">
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={days.map((day) => day._id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="days-list">
            {days.map((day) => (
              <SortableItem key={day._id} day={day} onUpdate={updateDay} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
}

export default DaysEditor;
