import React, { useState } from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";

import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import noImage from "../../assets/images/No_Image_Available.jpg";

import "./preview.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    maxWidth: "500px",
    margin: "0 auto",
    borderRadius: "10px",
    backgroundColor: "#f5f5f5", // Customize the background color of AppBar
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "white", // Customize the indicator color
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ overflow: "hidden" }} // Set overflow to hidden
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}


const MockupWrapper = ({ children, backgroundImage }) => (
  <div
    style={{
      position: "relative",
      maxWidth: "500px", // Adjust this to the desired width of the mockup
      margin: "0 auto",
      background: `url(${backgroundImage}) no-repeat center`,
      backgroundSize: "cover",
      height: "890px", // Adjust this to match the height of the mockup
    }}
  >
    {children}
  </div>
);

const mergeProps = (defaultProps, passedProps) => {
  let mergedProps = Object.assign({}, defaultProps);
  for (let key in passedProps) {
    if (passedProps[key] === undefined) {
      continue;
    }
    passedProps[key] = passedProps[key].toString();
    if (passedProps[key] && passedProps[key]?.trim() !== "") {
      mergedProps[key] = passedProps[key];
    }
  }
  return mergedProps;
};

const NotificationPreview = ({
  notificationDialog = defaultProps, 
  onTabChange}) => {
  const classes = useStyles();
  //const dialogData = mergeProps(defaultPropValues, notificationDialog);
    const dialogData = notificationDialog;

    console.log("dialogData", dialogData);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = useState("classic");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    // Update the active tab based on the selected index
    if (newValue === 0) {
      setActiveTab("classic");
      onTabChange("classic");
    } else if (newValue === 1) {
      setActiveTab("feed");
      onTabChange("feed");
    }

    // Call the callback to pass the activeTab value to the parent component
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const bgStyle = {
    // background: `linear-gradient(#f8f9fa, #e9ecef)`,
    minHeight: "600px",
  };

  const cardBgStyle = {
    background: dialogData?.bgColor || "#3B3B3B",
    marginTop: dialogData?.buttonText2 ? "125px" : "160px",
    borderRadius: "10px",
  };
  const cardBgStyle2 = {
    background: dialogData?.bgColor || "#3B3B3B",
    marginTop: dialogData?.buttonText2 ? "125px" : "160px",
    borderRadius: "10px",

    maxHeight: "410px",
    width: "242px",
  };

  return (
    <div className="notification-preview-container">
      <AppBar
        style={{
          maxWidth: "500px",
          margin: "auto",
          borderRadius: "10px",
          background: "#1A76D2",
        }}
        position="static"
        className={classes.appBar}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          <Tab label="IOS" {...a11yProps(0)} />
          <Tab label="Android" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabPanel value={value} index={0} dir={theme.direction}>
          <MockupWrapper backgroundImage="https://img.freepik.com/free-vector/realistic-front-view-smartphone-mockup-mobile-iphone-purple-frame-with-blank-white-display-vector_90220-959.jpg?w=740&t=st=1693026908~exp=1693027508~hmac=975859aab937e4ecc869e3db4d344a83985307c144eb04bfaa26a22bc8ae66ef">
            <div className=" mt-lg-0" style={bgStyle}>
              <div
                className="row mb-4 align-items-center"
                style={{ marginBottom: "50px" }}
              >
                <label className="col-sm-2 col-form-label text-center"></label>

                <div className="col-sm-8">
                  <div
                          className="customcomponent fix-heights"
                          style={{                            
                            textAlign: "start",
                            backgroundColor: dialogData.cardType === "bg-color" ? dialogData.cardTypeValue : "#1a1729",
                            borderRadius: "5px",
                            padding: "12px",
                            color:  dialogData.textColor || "#ffffff",
                            marginTop: "200px",                           
                            height: "100%",
                          }}
                        >
                          {/* Show Image if cardType is not bg-color */}
                         
                  
                          {/* Title and Description */}
                          <div className="dic" style={{ marginBottom: "20px", marginTop: "0px" }}>
                            <h5 className="title" style={{ fontSize: "18px", fontWeight: "bold" }}>
                              {dialogData.title ? dialogData.title : "Title"}
                            </h5>
                  
                            <div className="mb-3">
                              <h6 className="fnt-18" style={{ fontSize: "14px" }}>
                                {dialogData.description ? dialogData.description : "Description"}
                              </h6>
                            </div>
                          </div>

                          {dialogData.cardType !== "bg-color" && dialogData.image && (
                            <div className="image-container" style={{ marginBottom: "10px" }}>
                              <img
                                src={dialogData.image}
                                alt="Poll"
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                          )}
                  
                          {/* Options based on Type */}
                          <div className="bottom-footer mt-3">
                            {dialogData.type === "venue" ? (
                              <div className="options stylish-scrollbar" style={{ maxHeight: dialogData.cardType == "bg-color" ? "400px" : "300px", overflowY: "auto" }}>
                                {dialogData?.options?.map((venue, idx) => (
                                  <div
                                    key={idx}
                                    className="d-flex align-items-center"
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "6px",
                                      backgroundColor: dialogData.initialButtonColor,
                                      color: dialogData.textColor,
                                      paddingLeft: "8px",
                                      borderRadius: "5px"                                     
                                    }}
                                  >
                                    <div className="logo" style={{ marginRight: "4px", width: "40px" }}>
                                      <img
                                        src={venue.logo || noImage}
                                        alt="Venue"
                                        style={{ width: "30px", height: "30px" }}
                                      />
                                    </div>
                                    <div>
                                      <h3 style={{ fontSize: "12px", marginTop: "8px", marginBottom: "4px" }}>{venue.name}</h3>
                                      <p style={{ fontSize: "9px" }}>{ venue.address }</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : dialogData.type === "offer" ? (
                              <div className="options">
                                {dialogData?.options?.map((offer, idx) => (
                                  <div
                                    key={idx}
                                    className="d-flex align-items-center"
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "6px",
                                      backgroundColor: dialogData.initialButtonColor,
                                      color: dialogData.textColor,
                                      borderRadius: "5px",
                                      paddingLeft: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <div className="logo"  style={{ marginRight: "4px", width: "40px" }}>
                                      <img
                                        src={offer?.image || noImage}
                                        alt="Offer"
                                        style={{ width: "30px", height: "30px" }}
                                      />
                                    </div>
                                    <div>
                                      <h3 style={{ fontSize: "12px", marginTop: "8px", marginBottom: "4px" }}>{offer.title}</h3>
                                      <p  style={{ fontSize: "9px" }}>{offer.description}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : dialogData.type === "text" ? (
                              <div className="text-options">
                                {dialogData?.options?.map((option, idx) => (
                                  <div
                                    key={idx}
                                    className="d-flex align-items-center mb-3"
                  
                                    style={{
                                      cursor: "pointer",
                                      gap: "10px",
                                      backgroundColor: dialogData.initialButtonColor || "#282828",
                                      borderRadius: "5px",
                                      padding: "5px 12px"
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name={`text-option-${idx}`}
                                      id={`option-${idx}`}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        accentColor: dialogData.initialButtonColor,
                                       
                                      }}
                                    />
                                    <label
                                      htmlFor={`option-${idx}`}
                                      style={{
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        color: dialogData.textColor,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {option.text}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div
                                className="yes-no-options"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                  
                  
                                  {dialogData?.options?.map((option, idx) => (
                                    <button
                                      className="btn-option"
                                      style={{
                                        backgroundColor: dialogData.initialButtonColor,
                                        color: dialogData.textColor,
                                        border: "none",
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {option.text}
                                    </button>
                  
                                ))}            
                              </div>
                            )}
                          </div>
                  
                          {/* Vote Button */}
                          <div className="vote-button-container" style={{ marginTop: "20px" }}>
                            <button
                              style={{
                                backgroundColor: dialogData.voteBottonEnabledBgColor,
                                color: dialogData.textColor || "#fff",
                                border: "none",
                                borderRadius: "5px",
                                padding: "10px 20px",
                                width: "100%",
                                cursor: "pointer",
                              }}
                            >
                              {dialogData.voteBottonEnabledText}
                            </button>
                          </div>
                        </div>               
                </div>
              </div>
            </div>
          </MockupWrapper>
        </TabPanel>
      )}
      {value === 1 && (
        <TabPanel value={value} index={1} dir={theme.direction}>
          <MockupWrapper backgroundImage="https://img.freepik.com/free-vector/smartphone-realistic-mockup_78370-591.jpg?t=st=1736750950~exp=1736754550~hmac=df3900dfa77baa4169b6cc689dc0855b0519daa3d29724a079a07051f60cca52&w=740">
          <div className=" mt-lg-0" style={bgStyle}>
              <div
                className="row mb-4 align-items-center"
                style={{ marginBottom: "50px" }}
              >
                <label className="col-sm-2 col-form-label text-center"></label>

                <div className="col-sm-8">
                  <div
                          className="customcomponent fix-heights"
                          style={{                            
                            textAlign: "start",
                            backgroundColor: dialogData.cardType === "bg-color" ? dialogData.cardTypeValue : "#1a1729",
                            borderRadius: "5px",
                            padding: "12px",
                            color:  dialogData.textColor || "#ffffff",
                            marginTop: "200px",                           
                            height: "100%",
                          }}
                        >
                          {/* Show Image if cardType is not bg-color */}
                         
                  
                          {/* Title and Description */}
                          <div className="dic" style={{ marginBottom: "20px", marginTop: "0px" }}>
                            <h5 className="title" style={{ fontSize: "18px", fontWeight: "bold" }}>
                              {dialogData.title ? dialogData.title : "Title"}
                            </h5>
                  
                            <div className="mb-3">
                              <h6 className="fnt-18" style={{ fontSize: "14px" }}>
                                {dialogData.description ? dialogData.description : "Description"}
                              </h6>
                            </div>
                          </div>

                          {dialogData.cardType !== "bg-color" && dialogData.image && (
                            <div className="image-container" style={{ marginBottom: "10px" }}>
                              <img
                                src={dialogData.image}
                                alt="Poll"
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                          )}
                  
                          {/* Options based on Type */}
                          <div className="bottom-footer mt-3">
                            {dialogData.type === "venue" ? (
                              <div className="options stylish-scrollbar" style={{ maxHeight: dialogData.cardType == "bg-color" ? "400px" : "300px", overflowY: "auto" }}>
                                {dialogData?.options?.map((venue, idx) => (
                                  <div
                                    key={idx}
                                    className="d-flex align-items-center"
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "6px",
                                      backgroundColor: dialogData.initialButtonColor,
                                      color: dialogData.textColor,
                                      paddingLeft: "8px",
                                      borderRadius: "5px"                                     
                                    }}
                                  >
                                    <div className="logo" style={{ marginRight: "4px", width: "40px" }}>
                                      <img
                                        src={venue.logo || noImage}
                                        alt="Venue"
                                        style={{ width: "30px", height: "30px" }}
                                      />
                                    </div>
                                    <div>
                                      <h3 style={{ fontSize: "12px", marginTop: "8px", marginBottom: "4px" }}>{venue.name}</h3>
                                      <p style={{ fontSize: "9px" }}>{ venue.address }</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : dialogData.type === "offer" ? (
                              <div className="options">
                                {dialogData?.options?.map((offer, idx) => (
                                  <div
                                    key={idx}
                                    className="d-flex align-items-center"
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "6px",
                                      backgroundColor: dialogData.initialButtonColor,
                                      color: dialogData.textColor,
                                      borderRadius: "5px",
                                      paddingLeft: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <div className="logo"  style={{ marginRight: "4px", width: "40px" }}>
                                      <img
                                        src={offer?.image || noImage}
                                        alt="Offer"
                                        style={{ width: "30px", height: "30px" }}
                                      />
                                    </div>
                                    <div>
                                      <h3 style={{ fontSize: "12px", marginTop: "8px", marginBottom: "4px" }}>{offer.title}</h3>
                                      <p  style={{ fontSize: "9px" }}>{offer.description}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : dialogData.type === "text" ? (
                              <div className="text-options">
                                {dialogData?.options?.map((option, idx) => (
                                  <div
                                    key={idx}
                                    className="d-flex align-items-center mb-3"
                  
                                    style={{
                                      cursor: "pointer",
                                      gap: "10px",
                                      backgroundColor: dialogData.initialButtonColor || "#282828",
                                      borderRadius: "5px",
                                      padding: "5px 12px"
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name={`text-option-${index}`}
                                      id={`option-${index}-${idx}`}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        accentColor: dialogData.initialButtonColor,
                                       
                                      }}
                                    />
                                    <label
                                      htmlFor={`option-${index}-${idx}`}
                                      style={{
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        color: dialogData.textColor,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {option.text}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div
                                className="yes-no-options"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                  
                  
                                  {dialogData?.options?.map((option, idx) => (
                                    <button
                                      className="btn-option"
                                      style={{
                                        backgroundColor: dialogData.initialButtonColor,
                                        color: dialogData.textColor,
                                        border: "none",
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {option.text}
                                    </button>
                  
                                ))}            
                              </div>
                            )}
                          </div>
                  
                          {/* Vote Button */}
                          <div className="vote-button-container" style={{ marginTop: "20px" }}>
                            <button
                              style={{
                                backgroundColor: dialogData.voteBottonEnabledBgColor,
                                color: dialogData.textColor || "#fff",
                                border: "none",
                                borderRadius: "5px",
                                padding: "10px 20px",
                                width: "100%",
                                cursor: "pointer",
                              }}
                            >
                              {dialogData.voteBottonEnabledText}
                            </button>
                          </div>
                        </div>               
                </div>
              </div>
            </div>
          </MockupWrapper>
        </TabPanel>
      )}
    </div>
  );
};

export default NotificationPreview;
