import React, { useState } from "react";
import "./ThreeStateToggleButton.css";

const ThreeStateToggleButton = ({ initialState, onChange }) => {
  const [status, setStatus] = useState(initialState || "pending");

  const handleClick = (newStatus) => {
    setStatus(newStatus);
    onChange(newStatus); // Notify parent about the state change
  };

  return (
    <>
    {status === "pending" ? (
      <div className="three-state-toggle">
      <div className="button-group">
        <button
          className={`toggle-button ${status === "rejected" ? "selected-cancelled" : ""}`}
          onClick={() => handleClick("rejected")}
        >
           Rejected
        </button>
        <button
          className={`toggle-button ${status === "pending" ? "selected-pending" : ""}`}
          // onClick={() => handleClick("pending")}
        >
          Pending
        </button>
        <button
          className={`toggle-button ${status === "accepted" ? "selected-confirmed" : ""}`}
          onClick={() => handleClick("accepted")}
        >
          Accepted
        </button>
      </div>
    </div>
    ) : (
      <div className="three-state-toggle">
      <div className="button-group">
        <button
          className={`toggle-button ${status === "rejected" ? "selected-cancelled" : ""}`}
          // onClick={() => handleClick("rejected")}
        >
          Rejected
        </button>
        <button
          className={`toggle-button ${status === "pending" ? "selected-pending" : ""}`}
          // onClick={() => handleClick("pending")}
        >
          Pending
        </button>
        <button
          className={`toggle-button ${status === "accepted" ? "selected-confirmed" : ""}`}
          // onClick={() => handleClick("accepted")}
        >
          Accepted
        </button>
      </div>
    </div>
    )}</>


    


 
  );
};

export default ThreeStateToggleButton;
