import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
} from "../../components/Common/Utils.js";
import NotificationPreview from "./notificationPreview.js";
import noImage from "../../assets/images/No_Image_Available.jpg";
import Accordion from "react-bootstrap/Accordion";
import OfferModal from "components/Modal/OfferModal.js";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect.js";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "pages/VenueManagement/canvasPreview.js";
export default function NotificationForm() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  console.log("state", state);
  const [activeTab, setActiveTab] = useState("classic");

  const handleTabChange = (newTabValue) => {
    setActiveTab(newTabValue);
  };

  const [pollData, setPollData] = useState(state || {
    title: "",
    description: "",
    type: "",
    image: "",
    textColor: "#fff",
    options: [],
    cardType: "bg-color",
    cardTypeValue: "#1a1729",
    initialButtonColor: "#282828",
    selectedButtonColor: "#00a6ff",
    voteBottonDisabledBgColor: "#00FF00",
    voteBottonDisabledText: "Vote Disabled",
    voteBottonEnabledBgColor: "#5d5d5d",
    voteBottonEnabledText: "Vote",
    editBottonColor: "#FFFFFF",
    startDate: "",
    endDate: "",
    status: "upcomming",
    isPublished: true,
    isDeleted: false,
  }
  );

  const [categoryData, setCategoryData] = useState({
    title: "",
    description: "",
    type: "",
    image: "",
    textColor: "#fff",
    cardType: "bg-color",
    cardTypeValue: "#1a1729",
    initialButtonColor: "#282828",
    selectedButtonColor: "#00a6ff",
    voteBottonDisabledBgColor: "#00FF00",
    voteBottonDisabledText: "Vote Disabled",
    voteBottonEnabledBgColor: "#5d5d5d",
    voteBottonEnabledText: "Vote Enabled",
    editBottonColor: "#FFFFFF",
    startDate: "",
    endDate: "",
    status: "upcomming",
    isPublished: true,
    isDeleted: false,
  });




  const [buttonOptions, setButtonOptions] = useState([]);
const [textOptions, setTextOptions] = useState([]);
const [newOption, setNewOption] = useState(""); // To handle the new input


  const [previewImage, setPreviewImage] = useState("");
  const [previewBackgroundImage, setPreviewBackgroundImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [packagedata, setpackagedata] = useState([]);
  const [previewLogo, setPreviewLogo] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [yachtId, setYachtId] = useState("");
  const [yachtText, setYachtText] = useState("");
  const [yachtImage, setYachtImage] = useState("");
  const [yachtModel, setYachtModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [logoSelected, setLogoSelected] = useState(0);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);

  const [offers, setOffers] = useState([]);
  const [venues, setVenues] = useState([]);

  const { id } = useParams();

  const handleAddOption = () => {
    if (!newOption.trim()) return;  
    if (pollData.type === "button") {
      let tempPollData = { ...pollData };
      let options = [...buttonOptions];
      // convert list to array of objects
      options = options.map((opt) => ({ ...opt, text }));
      options.push({ text: newOption });
      setPollData({ ...tempPollData, options });
      setButtonOptions([...buttonOptions, { text: newOption }]);
    } else if (pollData.type === "text") {
      let tempPollData = { ...pollData };
      let options = [...textOptions];
      // convert list to array of objects
      options = options.map((opt) => ({ ...opt }));
      options.push({ text: newOption });
      setPollData({ ...tempPollData , options });
      setTextOptions([...textOptions, { text: newOption }]);
    }  
    setNewOption(""); // Clear input after adding
  };

  

  useEffect(() => {
    if (!remoteLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setLogoSelected(reader.result);
      const categoryTemp = { ...categoryData };
      categoryTemp.image = reader.result;
      setCategoryData(categoryTemp);
    };

    reader.readAsDataURL(remoteLogo);
  }, [remoteLogo]);

  useEffect(() => {

    if( state.type == "text"){
          let dt = state.options;
          console.log("dt", dt);
          setTextOptions(dt);
    } else if(state.type == "button" ) {
      let dt = state.options;
      console.log("dt", dt);
      setButtonOptions(dt);
    } else if(state.type == "venue" ) {
      let dt = state.options;
      console.log("dt", dt);
      setVenues(dt);
    } else if(state.type == "offer") {
      let dt = state.options;
      console.log("dt", dt);
      setOffers(dt);
    }

  }, [state]);

  // useEffect on packageId update

  useEffect(() => {
    const packageData = packagedata.find(
      (item) => item._id == categoryData.packageId
    );
    if (packageData) {
      const categoryTemp = { ...categoryData };
      categoryTemp.packageName = packageData.title;
      categoryTemp.packagePrice = packageData.discountedPrice;
      categoryTemp.packageDuration = packageData.time;
      setCategoryData(categoryTemp);
    }
  }, [categoryData.packageId]);

  useEffect(() => {
    if (!selectedBackgroundImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewBackgroundImage(reader.result);
      const categoryTemp = { ...categoryData };
      categoryTemp.backgroundImage = reader.result;
      setCategoryData(categoryTemp);
    };

    reader.readAsDataURL(selectedBackgroundImage);
  }, [selectedBackgroundImage]);

  const toggleModel = () => {
    setModel(!model);
    console.log("toggleModel", model);
  };

  const handleRemoveVenue = (id) => {
    const newVenues = venues.filter((item) => item._id !== id);
    const tempPollData = { ...pollData };
    tempPollData.options = newVenues;
    setPollData(tempPollData);
    setVenues(newVenues);
    };

    const handleRemoveOffer = (id) => {
        const newOffers = offers.filter((item) => item._id !== id);
        const tempPollData = { ...pollData };
        tempPollData.options = newOffers;
        setPollData(tempPollData);
        setOffers(newOffers);
    };

  const toggleModel2 = () => {
    setModel2(!model2);
    console.log("toggleModel", model2);
  };

  const closeModal2 = () => {
    setModel2(false);
    };

  const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Destructure necessary fields from pollData
        const {
          title,
          description,
          type,           
          startDate,
          endDate,
          textColor,
          cardType,
          cardTypeValue,
          initialButtonColor,
          selectedButtonColor,
          voteBottonDisabledBgColor,
          voteBottonDisabledText,
          voteBottonEnabledBgColor,
          voteBottonEnabledText,    
          editBottonColor,    
          image       
        } = pollData;
      
        // 1. Validate Required Fields
        if (
          !title.trim() ||
          !description.trim() ||
          (!image.trim() && cardType == "bg-image" )  ||
          !cardType.trim() ||
          !cardTypeValue.trim() ||
          !initialButtonColor.trim() ||
          !selectedButtonColor.trim() ||
          !voteBottonDisabledBgColor.trim() ||
          !voteBottonDisabledText.trim() ||
          !voteBottonEnabledBgColor.trim() ||
          !voteBottonEnabledText.trim() ||
          !textColor.trim() ||
          !cardType.trim() ||
          !cardTypeValue.trim() ||
          !initialButtonColor.trim() ||
          !selectedButtonColor.trim() ||
          !voteBottonDisabledBgColor.trim() ||
          !voteBottonDisabledText.trim() ||
          !voteBottonEnabledBgColor.trim() ||
          !voteBottonEnabledText.trim() ||
          !editBottonColor.trim() ||
          !type.trim() ||
          !startDate ||
          !endDate
        ) {
  
          console.log("pollData", pollData);
  
          toast.error("Please fill all required fields.");
          return;
        }
      
        // 2. Process Options Based on Type
        let options = [];
      
        if (type === "button" || type === "text") {
          if (type === "button") {
            options = buttonOptions
             
          } else {
  
            options = textOptions
              
          }
        } else if (type === "venue") {
          options = venues.map((venue) => ({ venueId: venue._id }));
        } else if (type === "offer") {
          options = offers.map((offer) => ({ offerId: offer._id }));
        } else {
          toast.error("Invalid type selected.");
          return;
        }
      
        // 3. Validate Options
        if (options.length < 2) {
          toast.error("At least two options are required.");
          return;
        }
      
        // 4. Prepare Data Object for Submission
        const dataObject = {
          pollId: state._id,
          title,
          description,
          type,
          options,
          textColor,
          cardType,
          cardTypeValue,
          initialButtonColor,
          selectedButtonColor,
          voteBottonDisabledBgColor,
          voteBottonDisabledText,
          voteBottonEnabledBgColor,
          voteBottonEnabledText,
          startDate,
          endDate,       
          editBottonColor,
          image           
        };
      
        // 5. Handle Image Uploads if Necessary
        try {
          setLoading(true);
          setStartLoader(true);       
      
          // 6. Make API Call to Submit Data
          const response = await postRequest("venue/poll/update", dataObject);
      
          // 7. Handle Response
          if (response.status === 1) {
            toast.success(response.message || "Poll created successfully!");
            setTimeout(() => {
              history.push("/poll");
            }, 2000);
          } else {
            toast.error(response.message || "Failed to create poll.");
          }
        } catch (error) {
          console.error("Error creating poll:", error);
          toast.error("An error occurred while creating the poll.");
        } finally {
          // 8. Reset Loading States
          setLoading(false);
          setStartLoader(false);
        }
      };


  
      const handleRemoveOption = (index) => {
        if (pollData.type === "button") {
          let tempPollData = { ...pollData };
          let options = buttonOptions.filter((_, i) => i !== index);
          setPollData({ ...tempPollData, options });
          setButtonOptions(buttonOptions.filter((_, i) => i !== index));
        } else if (pollData.type === "text") {
          let tempPollData = { ...pollData };
          let options = textOptions.filter((_, i) => i !== index);
          setPollData({ ...tempPollData, options });
          setTextOptions(textOptions.filter((_, i) => i !== index));
        }
      };

const blobUrlRef = useRef("");
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      // convert blob to file
      const file = new File([blob], categoryData?.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const closeModal = () => {
    setModel(false);
  };  

  const selectRow = (selectRow, dt, venueData) => {
    if (venues.find((item) => item._id === venueData._id)) {
        toast.error("Venue already added");
        return;
    }
    let tempPollData = { ...pollData };
    let options = [...venues, venueData];  
    setPollData({ ...tempPollData, options });
    setVenues([...venues, venueData]);
    closeModal();
  };

  const selectRow2 = (selectRow) => {
    // check if its not added already
    if (offers.find((item) => item._id === selectRow._id)) {
        toast.error("Offer already added");
        return;
    }    


    let tempPollData = { ...pollData };
    let options = [...offers, selectRow];

    console.log("options", options);

    setPollData({ ...tempPollData, options });

    setOffers([...offers, selectRow]);
    closeModal2();   

    


  };

  const createNotificationData = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!categoryData.title) {
      toast.error("Title is required");
      isValid = false;
    }
    if (!categoryData.subTitle) {
      toast.error("Please enter Subtitle");
      isValid = false;
    }

    if (!categoryData.type1) {
      toast.error("Please enter Button 1 Type");
      isValid = false;
    }
    if (
      !categoryData.data1 &&
      !venueId &&
      !offerId &&
      !eventId &&
      !activityId
    ) {
      toast.error("Please select at least one option");
      isValid = false;
    }

   

    if (!isValid) {
      return; // If any required field is missing, don't proceed
    }
    setStartLoader(true);
    setLoading(true);

    const titleObject = {
      text: categoryData.title,
      color: categoryData.titleColor || "#ffffff",
      alignment: categoryData.titleAlignment || "center",
    };

    const subTitleObject = {
      text: categoryData.subTitle,
      color: categoryData.subTitleColor || "#ffffff",
      alignment: categoryData.subTitleAlignment || "center",
    };

    const descObject = {
      text: categoryData.description,
      color: categoryData.descriptionColor || "#808080",
      alignment: categoryData.descAlignment || "center",
    };

    const btn1Object = {
      text: categoryData.buttonText1 || "Click Me",
      color: categoryData.btn1TextColor || "#000000",
      bgColor: categoryData.btn1Color || "#ffffff",
      action: categoryData.type1,
    };

    if (categoryData.type1 === "venue") {
      btn1Object.data = venueId;
    } else if (categoryData.type1 === "offer") {
      btn1Object.data = offerId;
    } else if (categoryData.type1 === "event") {
      btn1Object.data = eventId;
    } else if (categoryData.type1 === "activity") {
      btn1Object.data = activityId;
    } else if (categoryData.type1 === "link") {
      btn1Object.data = categoryData.data1;
    } else {
      btn1Object.data = null;
    }

    const btn2Object = {
      text: categoryData.buttonText2,
      color: categoryData.btn2TextColor || "#000000",
      bgColor: categoryData.btn2Color || "#ffffff",
      action: categoryData.type,
      data: categoryData.data,
    };
    if (categoryData.type === "venue") {
      btn2Object.data = venueId;
    } else if (categoryData.type === "offer") {
      btn2Object.data = offerId;
    } else if (categoryData.type === "event") {
      btn2Object.data = eventId;
    } else if (categoryData.type === "activity") {
      btn2Object.data = activityId;
    } else if (categoryData.type === "link") {
      btn2Object.data = categoryData.data;
    } else {
      btn2Object.data = null;
    }

    const backgroundObject = {
      color: categoryData.bgColor || "#3B3B3B", // Use the default value "#ffffff" if bgColor is not set
    };

    let selectedImageForm = new FormData();

    let selectedImageRes = null;

    if (categoryData.image) {
      selectedImageForm.append("image", remoteLogo);
      selectedImageRes = await postRequestForm(
        "comman/img/upload",
        selectedImageForm
      );
      setStartLoader(false);
    }

    let selectedBackgroundImageRes = null;

    if (categoryData.backgroundType == "image") {
      const selectedBackgroundImageForm = new FormData();
      selectedBackgroundImageForm.append("image", selectedBackgroundImage);
      selectedBackgroundImageRes = await postRequestForm(
        "comman/img/upload",
        selectedBackgroundImageForm
      );
    }
    // formData.append("image", selectedImage);
    if (selectedImageRes?.data?.url) {
      categoryData.image = selectedImageRes.data.url;
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    }

    if (categoryData.backgroundType == "image") {
      if (selectedBackgroundImageRes?.data?.url) {
        categoryData.backgroundImage = selectedBackgroundImageRes.data.url;
      } else {
        // Handle the case where the image URL is not available in the response
        console.error("Image URL not found in the response.");
      }
    } else {
      categoryData.backgroundImage = "";
    }

    categoryData.subId = id;

    const dataObject = {
      title: titleObject,
      subtitle: subTitleObject,
      description: descObject,
      button1: btn1Object,
      button2: btn2Object,
      background: backgroundObject,
      layout: activeTab,
      image: categoryData.image,
    };

    const response = await postRequest(
      "chat/sendInAppNotification",
      dataObject
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/inAppNotification");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (field, value) => {
    setPollData({ ...pollData, [field]: value });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Poll Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Update Poll</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      <Row>
        <Col lg={6}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                />
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Create Poll</h4>
                <Form onSubmit={handleSubmit}>
                  <Accordion defaultActiveKey="0">
                    {/* Basic Information */}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Basic Information</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb-4">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control"
                            value={pollData.title}
                            onChange={(e) =>
                              updateValue("title", e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            value={pollData.description}
                            onChange={(e) =>
                              updateValue("description", e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label>Type</label>
                          <select
                            className="form-select"
                            value={pollData.type}
                            onChange={(e) => updateValue("type", e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="venue">Venue</option>
                            <option value="offer">Offer</option>
                            <option value="text">Text</option>                            
                            <option value="button">Button</option>
                          </select>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Appearance */}
                    <Accordion.Item eventKey="1">
                    <Accordion.Header>Appearance</Accordion.Header>
                    <Accordion.Body>
                        <div className="mb-4">
                        <label>Text Color</label>
                        <input
                            type="color"
                            className="form-control"
                            value={pollData.textColor}
                            onChange={(e) => updateValue("textColor", e.target.value)}
                        />
                        </div>
                        <div className="mb-4">
                        <label>Card Type</label>
                        <select
                            className="form-select"
                            value={pollData.cardType}
                            onChange={(e) => updateValue("cardType", e.target.value)}
                        >
                            <option value="bg-color">Background Color</option>
                            <option value="bg-image">Background Image</option>
                        </select>
                        </div>

                        {pollData.cardType === "bg-color" ? (
                        <div className="mb-4">
                            <label>Card Type Value (Color)</label>
                            <input
                            type="color"
                            className="form-control"
                            value={pollData.cardTypeValue}
                            onChange={(e) => updateValue("cardTypeValue", e.target.value)}
                            />
                        </div>
                        ) : (
                        <div className="mb-4">
                            <label>Background Image</label>
                            <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={ async (e) => {
                                const file = e.target.files[0];
                                if (file) {
                                const reader = new FileReader();
                                reader.onload = () => setPreviewBackgroundImage(reader.result);
                                reader.readAsDataURL(file);

                                const uploadImage = new FormData();
                                uploadImage.append("image", file);
                                const uploadImageRes = await postRequestForm(
                                    "comman/img/upload",
                                    uploadImage
                                );
                                console.log("uploadImageRes", uploadImageRes);
                                updateValue("image", uploadImageRes.data.url);
                                }
                            }}
                            />
                            {previewBackgroundImage && (
                            <img
                                src={previewBackgroundImage}
                                alt="Preview"
                                style={{ height: "auto", marginTop: "10px", height: "100px" }}
                            />
                            )}
                        </div>
                        )}
                    </Accordion.Body>
                    </Accordion.Item>

                    {/* Buttons */}
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Buttons</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb-4">
                          <label>Initial Button Color</label>
                          <input
                            type="color"
                            className="form-control"
                            value={pollData.initialButtonColor}
                            onChange={(e) =>
                              updateValue("initialButtonColor", e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label>Selected Button Color</label>
                          <input
                            type="color"
                            className="form-control"
                            value={pollData.selectedButtonColor}
                            onChange={(e) =>
                              updateValue("selectedButtonColor", e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label>Vote Button Disabled Background Color</label>
                          <input
                            type="color"
                            className="form-control"
                            value={pollData.voteBottonDisabledBgColor}
                            onChange={(e) =>
                              updateValue(
                                "voteBottonDisabledBgColor",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label>Vote Button Disabled Text</label>
                          <input
                            type="text"
                            className="form-control"
                            value={pollData.voteBottonDisabledText}
                            onChange={(e) =>
                              updateValue(
                                "voteBottonDisabledText",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        <div className="mb-4">
                          <label>Vote Button Enabled Background Color</label>
                          <input
                            type="color"
                            className="form-control"
                            value={pollData.voteBottonEnabledBgColor}
                            onChange={(e) =>
                              updateValue(
                                "voteBottonEnabledBgColor",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label>Vote Button Enabled Text</label>
                          <input
                            type="text"
                            className="form-control"
                            value={pollData.voteBottonEnabledText}
                            onChange={(e) =>
                              updateValue(
                                "voteBottonEnabledText",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        <div className="mb-4">
                          <label>Edit Button Color</label>
                          <input
                            type="color"
                            className="form-control"
                            value={pollData.editBottonColor}
                            onChange={(e) =>
                              updateValue(
                                "editBottonColor",
                                e.target.value
                              )
                            }
                          />
                        </div>

                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Options */}
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Options</Accordion.Header>
                      <Accordion.Body>
                        <p>Add options for your poll here.</p>

                        {  ["button", "text"].includes(pollData.type) && (
                            <div className="mb-4">
                            <input
                              type="text"
                              className="form-control"
                              value={newOption}
                              onChange={(e) => setNewOption(e.target.value)}
                              placeholder="Add new option"
                            />
                            <button className="btn btn-primary mt-2" onClick={handleAddOption} type="button">
                              Add Option
                            </button>
                          </div>
                        )}

                        {pollData.type === "button" &&
                                buttonOptions.map((option, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2"> 
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-block"
                                        onClick={() => handleRemoveOption(index)}
                                        style={{ border: "none", borderRadius: "5px", color: pollData.textColor,  textAlign: "center",   display: "flex", flexDirection: "column", width: "-webkit-fill-available", backgroundColor: pollData.initialButtonColor
                                        }}
                                    >
                                        {option.text} - Click to Remove
                                    </button>
                                    </div>
                                ))}
                                {pollData.type === "text" &&
                                textOptions.map((option, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                    <input
                                        type="radio"
                                        name="textOptionsPreview"
                                        disabled
                                        style={{ marginRight: "10px" }}
                                    />
                                    <label>{option.text}</label>
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm ms-2"
                                       
                                        onClick={() => handleRemoveOption(index)}
                                    >
                                        Remove 
                                    </button>
                                    </div>
                                ))}

                            { pollData.type === "venue" && (

                                <>
                                <Button
                                    onClick={toggleModel}
                                    className="btn btn-info"
                                    style={{ marginLeft: "2rem" }}
                                >
                                    <i className="fas fa-grip-vertical"></i>{" "}
                                    Add Venue
                                </Button> <br/><br/>

                                    <div className="row mb-4 align-items-center">
                                                              {venues.map((item, index) => (
                                                                <div className="col-lg-4 mb-3">
                                                                  <div
                                                                    class="box-custom"
                                                                    className={
                                                                      item
                                                                        ? "box-custom bgcard fix-heights"
                                                                        : "box-custom fix-heights"
                                                                    }
                                                                    style={{
                                                                      border: "1px solid #ccc",
                                                                      textAlign: "start",
                                                                      maxHeight: "200px",
                                                                    //   paddingTop: "30%",
                                                                       backgroundImage: `url(${item?.cover1})`,
                                                                      backgroundRepeat: "no-repeat",
                                                                      backgroundSize: "cover",
                                                                    }}
                                                                  >
                                                                    <div className="bottom-footer mt-0">
                                                                      <div className="d-flex align-items-center mb-3">
                                                                        <div className="logo">
                                                                          <img
                                                                            src={item?.logo || noImage}
                                                                            alt="logo"
                                                                          />
                                                                        </div>
                                                                        <div>
                                                                          <h3>
                                                                            {item?.name
                                                                              ? item?.name
                                                                              : "Select Offer"}
                                                                          </h3>
                                                                          <p>
                                                                            {item?.address
                                                                              ? item?.address
                                                                              : "Address"}
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div className="dic">
                                                                      {/* <p className="mb-0">
                                                                        {item?.name ? item?.name : "Title"}
                                                                      </p>
                                                                      <h3 className="limit-text">
                                                                        {item?.description
                                                                          ? item?.description
                                                                          : "Description"}
                                                                      </h3> */}
                                                                    </div>
                                                                  </div>
                                                                  <div style={{ textAlign: "start" }}>
                                                                    <button
                                                                      onClick={() =>
                                                                        handleRemoveVenue(item?._id)
                                                                      }
                                                                      className="btn-remove"
                                                                      type="button"
                                                                    >
                                                                      Remove
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              ))}                                                              
                                    </div>



                                </>


                            )}

                            { pollData.type === "offer" && (
                                <>
                                <Button
                                    onClick={toggleModel2}
                                    className="btn btn-info"
                                    style={{ marginLeft: "2rem" }}
                                >
                                    <i className="fas fa-grip-vertical"></i>{" "}
                                    Add Offer
                                </Button> <br/><br/>

                                <div className="row mb-4 align-items-center">
                                                              {offers.map((item, index) => (
                                                                <div className="col-lg-4 mb-3">
                                                                  <div
                                                                    class="box-custom"
                                                                    className={
                                                                      item
                                                                        ? "box-custom bgcard fix-heights"
                                                                        : "box-custom fix-heights"
                                                                    }
                                                                    style={{
                                                                      border: "1px solid #ccc",
                                                                      textAlign: "start",
                                                                      height: "200px",

                                                                    //   paddingTop: "30%",
                                                                      backgroundImage: `url(${item?.image})`,
                                                                      backgroundRepeat: "no-repeat",
                                                                      backgroundSize: "cover",
                                                                    }}
                                                                  >
                                                                    <div className="bottom-footer mt-0">
                                                                      <div className="d-flex align-items-center mb-3">
                                                                        {/* <div className="logo">
                                                                          <img
                                                                            src={item?.venue?.logo || noImage}
                                                                            alt="logo"
                                                                          />
                                                                        </div> */}
                                                                        <div>
                                                                          <h3>
                                                                            {item?.title
                                                                              ? item?.title
                                                                              : "Select Offer"}
                                                                          </h3>
                                                                          <br/><br/>
                                                                          <p>
                                                                            {item?.description
                                                                              ? item?.description
                                                                              : "Description"}
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div className="dic">
                                                                      {/* <p className="mb-0">
                                                                        {item?.name ? item?.name : "Title"}
                                                                      </p>
                                                                      <h3 className="limit-text">
                                                                        {item?.description
                                                                          ? item?.description
                                                                          : "Description"}
                                                                      </h3> */}
                                                                    </div>
                                                                  </div>
                                                                  <div style={{ textAlign: "start" }}>
                                                                    <button
                                                                      onClick={() =>
                                                                        handleRemoveOffer(item?._id)
                                                                      }
                                                                      className="btn-remove"
                                                                      type="button"
                                                                    >
                                                                      Remove
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              ))}                                                              
                                    </div>



                                </>
                            )}

                                
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Dates */}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Dates</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb-4">
                          <label>Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={pollData.startDate}
                            onChange={(e) =>
                              updateValue("startDate", e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label>End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={pollData.endDate}
                            onChange={(e) =>
                              updateValue("endDate", e.target.value)
                            }
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div className="mt-4 text-end">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>

        <VenueModal
            show={model}
            closeModal={closeModal}
            selectRow={selectRow}
            action="homeblock"
       />
       
       <OfferModal
        show={model2}
        closeModal={closeModal2}
        selectRow={selectRow2}        
      />

	    <Col lg={6} className="mt-3 ms-lg-auto">
                      <NotificationPreview
                        notificationDialog={pollData}
                        onTabChange={handleTabChange}
                      /></Col>
      </Row>
    </React.Fragment>
  );
}
