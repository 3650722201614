import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  postRequestForm,
  postRequest,
  customValidator, // (Still imported if you want, though not used for field-level errors)
} from "../../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import FileUpload from "components/Common/FileUpload.js";
import VenueModal from "../../../components/Modal/VenueModalOrg.js";

export default function CreateVenueTable() {
  const [categoryData, setCategoryData] = useState({
    title: "",
    subTitle: "",
    startColor: "",
    endColor: "",
    selectedImage: "",
    description: "",
    capacity: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [model, setModel] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [bussinessData, setBussinessData] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  let history = useHistory();

  // Validation errors state
  const [errors, setErrors] = useState({});

  // Load preview of selected file
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  // Show the Lightbox
  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  // Venue Modal selection
  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  // Toggle modal
  const closeModal = () => {
    setModel(false);
  };
  const toggleModel = () => {
    setModel(!model);
  };

  // Update field in categoryData
  const updateValue = (field, value) => {
    setCategoryData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // -----------------------------
  // VALIDATION LOGIC
  // -----------------------------
  const validateForm = () => {
    const tempErrors = {};
    const data = categoryData;

    // Title is required
    if (!data.title || !data.title.trim()) {
      tempErrors.title = "Title is required.";
    }

    // Description is required
    if (!data.description || !data.description.trim()) {
      tempErrors.description = "Description is required.";
    }

    // Capacity is required
    if (!data.capacity || !data.capacity.trim()) {
      tempErrors.capacity = "Capacity is required.";
    } else if (parseInt(data.capacity, 10) < 0) {
      tempErrors.capacity = "Capacity cannot be negative.";
    }
    // If you want selectedImage to be required, uncomment:
    if (!selectedImage) {
      tempErrors.selectedImage = "Image is required.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // -----------------------------
  // FORM SUBMISSION
  // -----------------------------
  const Createcategory = async (e) => {
    e.preventDefault();

    // Validate before proceeding
    if (!validateForm()) {
      toast.error("Please correct the highlighted errors and try again.");
      return;
    }

    // If valid, proceed with submission
    setStartLoader(true);

    // Upload selected image
    let selectedImageUrl = "";
    if (selectedImage) {
      const selectedImageForm = new FormData();
      selectedImageForm.append("image", selectedImage);
      const selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
      selectedImageUrl = selectedImageRes?.data?.url || "";
    }

    setStartLoader(false);
    setLoading(true);

    // Build the payload
    const vId = localStorage.getItem("stored_venueId"); // If needed
    const payload = {
      // venueId: venueId, // Or use the localStorage one if that is your logic
      venueId: vId,
      title: categoryData.title,
      description: categoryData.description,
      capacity: categoryData.capacity,
      images: selectedImageUrl,
      banners: [businessId], // If you need this from your code
    };

    const response = await postRequest("venue/table/add", payload);
    setLoading(false);

    if (response.status === 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/venue-account/venue-table");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Venue Table Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Table Management</li>:{" "}
              <li className="breadcrumb-item">Create Venue Table</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Venue Table </h4>
                <Form onSubmit={Createcategory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        {/* Title */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) => updateValue("title", e.target.value)}
                              name="title"
                              className={`form-control ${
                                errors.title ? "is-invalid" : ""
                              }`}
                            />
                            {errors.title && (
                              <div className="invalid-feedback d-block">
                                {errors.title}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Description */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className={`form-control ${
                                errors.description ? "is-invalid" : ""
                              }`}
                            />
                            {errors.description && (
                              <div className="invalid-feedback d-block">
                                {errors.description}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Capacity */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Capacity
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => updateValue("capacity", e.target.value)}
                              name="capacity"
                              className={`form-control ${
                                errors.capacity ? "is-invalid" : ""
                              }`}
                            />
                            {errors.capacity && (
                              <div className="invalid-feedback d-block">
                                {errors.capacity}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Image */}
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) => setSelectedImage(e.target.files[0])}
                              name="image"
                              className={`form-control ${
                                errors.selectedImage ? "is-invalid" : ""
                              }`}
                              id="horizontal-password-input"
                            />
                            {errors.selectedImage && (
                              <div className="invalid-feedback d-block">
                                {errors.selectedImage}
                              </div>
                            )}
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px", cursor: "pointer" }}
                                alt="Preview"
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {/* Lightbox */}
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      {/* File uploading spinner (optional) */}
      <FileUpload message="File Uploading" status={startLoader} />

      {/* Venue Modal if you need it */}
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
    </React.Fragment>
  );
}
