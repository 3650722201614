import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  postRequestForm,
  pushUpdates,
} from "../../components/Common/Utils.js";

import PackageModal from "components/Modal/PackageModal.js";

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import Lightbox from "react-image-lightbox";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import EventOrgModal from "../../components/Modal/EventOrgModal.js";
import ActivityProviderModal from "../../components/Modal/ActivityProviderModal.js";
import CancelPolicyModal from "../../components/Modal/CancelPolicyModal.js";
import OfferPackageModal from "../../components/Modal/OfferPackageModal.js";
import IconPackModal from "components/Modal/IconPackModal.js";
import FileUpload from "components/Common/FileUpload.js";

export default function CreateVoucher() {
  const location = useLocation();
  const state = location.state?.row;
    const [offerData, setHomeBlockData] = useState({ type: "video" });
    const [validTimeRange, setValidTimeRange] = useState({ startTime: '', endTime: '', range: '' });
   const [dateInputValue, setDateInputValue] = useState('');
  const [voucherData, setVoucherData] = useState({ type: "video" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [venueList, setVenueList] = useState([]);
  const [iconModal, setIconModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [openFeature, setOpenFeature] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [currentPolicyData, setCurrentPolicyData] = useState(null);
  const [policyRules, setPolicyRules] = useState([]);
  const [venueTimings, setVenueTimings] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [venueDays, setVenueDays] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);

  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(false);
  const [modelOrg, setModelOrg] = useState(false);
  const [modelActivity, setModelActivity] = useState(false);
  const [features, setFeatures] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [eventOrgId, setEventOrgId] = useState("");
  const [eventOrgText, setEventOrgText] = useState("");
  const [eventOrgImage, setEventOrgImage] = useState("");

  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");

  const [discountList, setDiscountList] = useState([]);
  const [startLoader, setStartLoader] = useState(false);

  const [availableTime, setAvailableTime] = useState("regular");
  const [timeSlots, setTimeSlots] = useState([]);

  const [regularTime, setRegularTime] = useState({
    start: "",
    end: "",
  });


  const today = new Date().toISOString().split("T")[0];

  // apply useeffect for venueDays

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${((+hours % 12) || 12)}:${minutes} ${+hours < 12 ? 'AM' : 'PM'}`;
  };

  const isClosed = (timing) => !timing;

  // Function to render the status badge
  const renderStatusBadge = (isOpen) => (
    <span style={{
      display: 'inline-block',
      padding: '0.25em 0.6em',
      fontSize: '75%',
      fontWeight: '700',
      lineHeight: '1',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'baseline',
      borderRadius: '0.25rem',
      color: '#fff',
      backgroundColor: isOpen ? '#28a745' : '#dc3545',
    }}>
      {isOpen ? 'OPENING' : 'CLOSING'}
    </span>
  );

  useEffect(() => {
    //console.log("venueTimings", venueTimings);
  }, [venueTimings]);


  useEffect(() => {
    const calculateValidTimeRange = (selected) => {
      const filteredTimings = venueTimings.filter(timing => selected.includes(timing.day));

      const maxStartTime = Math.max(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.openingTime}`).getTime()));
      const minEndTime = Math.min(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.closingTime}`).getTime()));

      const validStartTime = new Date(maxStartTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validEndTime = new Date(minEndTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validRange = `${validStartTime} - ${validEndTime}`;

      setValidTimeRange({ startTime: validStartTime, endTime: validEndTime, range: validRange });
    };

    if (!selectedDay.length) return;
    calculateValidTimeRange(selectedDay);
  }, [selectedDay, venueTimings]);


  // useEffect(() => {
  //   //console.log("ValidTimeRange", validTimeRange);
  // }, [validTimeRange]);


  useEffect(() => {
    if (!selectedDay.length) return;

    //console.log("selectedDay", selectedDay);

    // Function to find the next date for any of the selected days
    const getNextDate = (days) => {
      const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      let today = new Date();
      let nextDate = new Date();

      // Find the next date that matches one of the selected days
      for (let i = 0; i < 7; i++) {
        const dayIndex = (today.getDay() + i) % 7;
        const dayName = daysOfWeek[dayIndex];

        if (days.includes(dayName)) {
          nextDate.setDate(today.getDate() + i);
          return nextDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
        }
      }

      return today.toISOString().split('T')[0];
    };

    // Update date input based on selected days
    const nextDate = getNextDate(selectedDay);
    setDateInputValue(nextDate);

  }, [selectedDay]);

  const isDayEnabled = (date) => {
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const dayName = daysOfWeek[date.getDay()];
    return selectedDay.includes(dayName);
  };

  useEffect(() => {
    if (!venueDays) return;

    const fullDayNames = {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday'
    };

    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);


  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("discount/list", { limit: 10000000 });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const updateFeatureField = (index, fieldName, value) => {
    const newFeatures = [...features];
    newFeatures[index][fieldName] = value;
    setFeatures(newFeatures);
  };

  const addRow = () => {
    setFeatures([...features, { icon: "", title: "", iconName: "" }]);
  };

  const saveFeature = (index) => {
    const newFeatures = [...features];
    newFeatures[index] = { ...newFeatures[index], saved: true };
    setFeatures(newFeatures);

    const featureToSave = newFeatures[index];
    // Add your own save logic here, such as API call etc.
  };

  const deleteFeature = (index) => {
    const newFeatures = features.filter((item, i) => i !== index);
    setFeatures(newFeatures);
  };

  const getTodayDayAbbreviation = () => {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const today = new Date();
    return days[today.getDay()];
  };

    // Utility function to find the next available timing
const findNextAvailableTiming = (timings, startDayIndex) => {
  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  for (let i = 0; i < days.length; i++) {
    const dayIndex = (startDayIndex + i) % days.length;
    const day = days[dayIndex];
    const timing = timings.find(t => t.day.toLowerCase() === day);
    if (timing) {
      return timing;
    }
  }
  return null;
};

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    setVenueTimings(venue?.timings || []);

    const days = venue?.timings?.map(item => item.day.toLowerCase());
    setVenueDays(days);
    closeModalVenue();

     // Determine today's day abbreviation
     const todayDay = getTodayDayAbbreviation();

     // Find timing for today
     let todayTiming = venue?.timings?.find(
       item => item.day.toLowerCase() === todayDay
     );
 
     if (todayTiming) {
       setRegularTime({
         start: todayTiming.openingTime,
         end: todayTiming.closingTime,
       });
     } else {
       // If today is not available, find the next available timing
       const daysOrder = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
       const todayIndex = daysOrder.indexOf(todayDay);
       const nextTiming = findNextAvailableTiming(venue?.timings, todayIndex + 1);
       
       if (nextTiming) {
         setRegularTime({
           start: nextTiming.openingTime,
           end: nextTiming.closingTime,
         });
       } else {
         // Handle case where no timings are available
         setRegularTime({
           start: "",
           end: "",
         });
       }
     }
  };

  const selectRowEventOrg = (id) => {  
    setEventOrgId(id?._id);
    setEventOrgText(id?.name);
    setEventOrgImage(id?.logo);
    closeModalEventOrg();
  };

  const selectRowActivity = (id, name, venue) => {
    setActivityId(venue?._id);
    setActivityText(venue?.name);
    setActivityImage(venue?.logo);    
    closeModalActivity();
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleModelEventOrg = () => {
    setModelOrg(!modelOrg);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const closeModalEventOrg = () => {
    setModelOrg(false);
  };

  const closeModalActivity = () => {
    setModelActivity(false);
  };

  const closeFeatureModal = () => {
    setOpenFeature(false);
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const selectIconRow = (icon) => {
    const featuresList = [...features];
    featuresList[selectedIcon].icon = icon.image;
    featuresList[selectedIcon].iconName = icon.title;
    setFeatures(featuresList);
    closeIconModal();
  };

  //const [day]

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...features];
    list[index].discount = value;
    setFeatures(list);
  };

  let history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!state) return;

    if (state?.venueId) {
      setVenueId(state?.venueId);
      setVenueText(state?.venueName);
    }

    if (state?.categoryId) {
      setCategoryId(state?.categoryId);
      const tempData = { ...voucherData };
      tempData.categoryId = state?.categoryId;
      setVoucherData(tempData);
    }

  }, [state]);

  useEffect(() => {
    fetchList();
    fetchCategoryList();

    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchfFeatures = async () => {
    setLoading(true);
    const response = await postRequest("subscription/membership/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setFeatures(response.data.list);
    }
    setLoading(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);
    }
    setLoading(false);
  };

  const updateRegulaTime = (type, value) => {
    const updatedSlots = { ...regularTime };
    updatedSlots[type] = value;
    setRegularTime(updatedSlots);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setCategoryList(list);
    }
    setLoading(false);
  };


  const addPolicyData = (data) => {    
    console.log("data", data);
    let venueDt = {...voucherData}
    venueDt.cancelPolicy = data;
    data.unit = "day";
    setVoucherData(venueDt);   
  }


  const editPolicyData = (index) => {
    let currentPolicyRule = policyRules[index];
    setCurrentPolicyData(currentPolicyRule);
    setPolicyModal(true);
  }

  const deletePolicyData = (index) => {
    let policyLists = [...policyRules];
    policyLists.splice(index, 1);
    setPolicyRules(policyLists);
  }
  

  function closePolicyModal() {
    setPolicyModal(false);
  }

  const createVoucher = async (e) => {
    e.preventDefault();

    // check title

    if (!voucherData.title) {
      toast.error("Please enter title");
      return;
    }

    // check description

    if (!voucherData.description) {
      toast.error("Please enter description");
      return;
    }

    // check category

    if (!voucherData.categoryId) {
      toast.error("Please select category");
      return;
    }

    // check venue

    if(!voucherData.type){  
      toast.error("Please select type");
      return;
    }

    // check type venue && venueId is selected

    if(voucherData.type == "venue"){
      if(!venueId){
        toast.error("Please select venue");
        return;
      }
    }

    if(voucherData.type == "event-org"){
      if(!eventOrgId){
        toast.error("Please select event org");
        return;
      }
    }

    if(voucherData.type == "activity-provider"){
      if(!activityId){
        toast.error("Please select activity provider");
        return;
      }
    }    

    // check days

    if (selectedDay?.length == 0) {
      toast.error("Please select days");
      return;
    }

    // check actual price

    if (!voucherData.actualPrice) {
      toast.error("Please enter actual price");
      return;
    }

    // check discounted price

    // if (!voucherData.discountedPrice) {
    //   toast.error("Please enter discounted price");
    //   return;
    // }

    // discount price should be less than actual price

    // if (voucherData.discountedPrice > voucherData.actualPrice) {
    //   toast.error("Discounted price should be less than actual price");
    //   return;
    // }

    // if (voucherData.discountedPrice > voucherData.actualPrice) {
    //   toast.error("Discounted price should be less than actual price");
    //   return;
    // }

    if (
      voucherData.discountedPrice > voucherData.actualPrice ||
      !voucherData.discountedPrice
    ) {
      if (voucherData.discountedPrice > voucherData.actualPrice) {
        toast.error("Discounted price should be less than actual price");
      } else {
        toast.error("Please enter discounted price");
      }
      return;
    }

    // check discounted value 

    if (!voucherData.discountValue) {
      toast.error("Please enter discount value");
      return;
    }

    // check discount value is less than 100

    if (voucherData.discountValue > 100) {
      toast.error("Discount value should be less than 100");
      return;
    }

    // if (!voucherData.paxPerVoucher) {
    //   toast.error("Please enter per person voucher");
    //   return;
    // }

    if (voucherData.paxPerVoucher < 1) {
      toast.error("Pax per voucher must be greater than 0");
      return;
    }

    // check discounted price

    if (!voucherData.startDate) {
      toast.error("Please enter start date");
      return;
    }

    if (!voucherData.startTime) {
      toast.error("Please enter start time");
      return;
    }


    if (!voucherData.endDate) {
      toast.error("Please enter end date");
      return;
    }

    if (!voucherData.endTime) {
      toast.error("Please enter end time");
      return;
    }

    // combine date and time

    let startDate = null;
    let endDate = null;

    if (voucherData.startDate) {
      if (voucherData.startTime) {
        startDate = new Date(voucherData.startDate + " " + voucherData.startTime);
      } else {
        startDate = new Date(voucherData.startDate + " " + "00:00");
      }
    }

    if (voucherData.endDate) {
      if (voucherData.endTime) {
        endDate = new Date(voucherData.endDate + " " + voucherData.endTime);
      } else {
        endDate = new Date(voucherData.endDate + " " + "00:00");
      }
    }

    // check if enddate > startdate

    if (startDate && endDate) {
      // if (startDate >= endDate) {
      //   toast.error("End date should be greater than start date");
      //   return;
      // }
    }



    // check image

    if (!selectedImage) {
      toast.error("Please select image");
      return;
    }

    // check atleast 2 features selected

    // if (features?.length < 2) {
    //   toast.error("Please select atleast 2 features");
    //   return;
    // }

    // check each feature have icon & title set

    // for (let i = 0; i < features.length; i++) {
    //   if (!features[i].icon) {
    //     toast.error("Please select icon for feature " + (i + 1));
    //     return;
    //   }

    //   if (!features[i].title) {
    //     toast.error("Please enter title for feature " + (i + 1));
    //     return;
    //   }
    // }


    setStartLoader(true);
    setLoading(true);

    const search = location.search;
    const from = new URLSearchParams(search).get("from");
    // combine date and time

    // let startDate = null;
    // let endDate = null;


    const x = new FormData();

    if (selectedImage) {
      x.append("image", selectedImage);
    }

    const imgres = await postRequestForm("comman/img/upload", x);
    setStartLoader(false);
    const dataObj = {
      title: voucherData.title,
      description: voucherData.description,
      providerType: voucherData.type,
      categoryId: voucherData.categoryId,
      days: selectedDay.join(","),
      startDate: voucherData.startDate,
      startTime: voucherData.startTime,
      endDate: voucherData.endDate,
      endTime: voucherData.endTime,
      actualPrice: voucherData.actualPrice,
      discountedPrice: voucherData.discountedPrice,
      discountValue: voucherData.discountValue,
      image: imgres?.data.url,
      paxPerVoucher: voucherData.paxPerVoucher,
      buttonText: voucherData.buttonText,
      features: features,
    };

        let dealTiming = {};
        if (availableTime === "regular") {
          if (!regularTime?.start || !regularTime?.end) {
            toast.error("Please select regular time");
            return;
          }
          dealTiming = {
            type: "regular",
            time: `${regularTime?.start}-${regularTime?.end}`,
            totalSeats: voucherData?.totalSeats
          };
          dataObj.dealTiming = dealTiming;          
        }

        if(voucherData.cancelPolicy){         
          dataObj.cancelPolicy = voucherData.cancelPolicy; 
        }

    if(voucherData.type == "venue"){
      dataObj.venueId = venueId;
    }

    if(voucherData.type == "event-org"){
      dataObj.eventOrgId = eventOrgId;
    }

    if(voucherData.type == "activity-provider"){
      dataObj.activityProviderId = activityId;
    }

    const response = await postRequest(
      "homeblock/deal-package/create",
      dataObj
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success("Vouchar created successfully");
      // setTimeout(() => {
      //   if (!from) history.goBack();
      //   else history.push("/venuedetails/" + venueId + "?active=Offers");
      // }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...voucherData };
    if (index == 'discountValue') {
      if (!list.actualPrice) {
        toast.error("Please Enter Actual Price First");
        return false
      }
      list['discountedPrice'] = list?.actualPrice - (value * list?.actualPrice) / 100;
    }
    if (index == 'actualPrice') {
      if (list.discountValue) {
        list['discountedPrice'] = value - (list.discountValue * value) / 100;
      }else{
        list['discountedPrice'] = value 
     }
    }
    list[index] = value;
    setVoucherData(list);
  };

  const addFeature = () => {
    setOpenFeature(true);
  };

  const addFeatureData = (data) => {
    setFeatures([...features, data]);
    setOpenFeature(false);
  };

  const updateFeatureData = (index, field, value) => {
    const newFeatures = [...features];
    newFeatures[index][field] = value;
    setFeatures(newFeatures);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Hot Deals Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Hot Deals</li>:{" "}
              <li className="breadcrumb-item">Create</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Voucher </h4>
                <Form onSubmit={createVoucher}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("categoryId", e.target.value)
                              }
                              defaultValue={categoryId}
                              name="categoryId"
                              class="form-select"
                            >
                              <option value="">Select Category</option>
                              {categoryList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              // defaultValue={"venue"}
                              name="categoryId"
                              class="form-select"
                            >
                              <option value="">Select Type</option>                             
                              <option value="venue">Venue</option>
                            </select>
                          </div>
                        </div>

                        {
                          voucherData.type == "venue" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        }


                        {
                          voucherData.type == "event-org" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Event Org
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModelEventOrg()}
                            >
                              {eventOrgText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={eventOrgImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={eventOrgText}
                                    id="selectedVenue"
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        } 

{
                          voucherData.type == "activity-provider" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activty Provider
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModelActivity() }
                            >
                              {activityText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelActivity()}
                                  >
                                    Choose Activty Provider
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={activityText}
                                    id="selectedVenue"
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelActivity()}
                                  >
                                    Choose Activty Provider
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        }  




                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Days
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              selectedValues={[]}
                              placeholder="Select Days"
                            />
                          </div>
                        </div>

                        {venueTimings && venueTimings.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Venue Timings
                            </label>
                            <div className="col-sm-6">

                              <div className="venue-timings-table">
                                {venueTimings.map((timing, index) => (
                                  <div key={index} className="timing-row" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto', alignItems: 'center', borderBottom: '1px solid #ccc' }}>
                                    <div className="day-cell" style={{ padding: '8px', backgroundColor: isClosed(timing) ? '#f8f9fa' : 'transparent', gridColumn: isClosed(timing) ? '1 / span 3' : '1' }}>
                                      {timing.day.charAt(0).toUpperCase() + timing.day.slice(1)}
                                    </div>
                                    {!isClosed(timing) && (
                                      <>
                                        <div className="status-cell" style={{ padding: '8px' }}>
                                          {renderStatusBadge(true)}
                                        </div>
                                        <div className="time-cell" style={{ padding: '8px' }}>
                                          {timing.openingTime} - {timing.closingTime}
                                        </div>
                                      </>
                                    )}
                                    {isClosed(timing) && (
                                      <div className="status-cell" style={{ padding: '8px' }}>
                                        {renderStatusBadge(false)}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>

                            </div>
                          </div>
                        )}


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Actual Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("actualPrice", e.target.value)
                              }
                              name="actualPrice"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>{" "}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Value (In Percent)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discountValue", e.target.value)
                              }
                              name="discountValue"
                              min={5}
                              max={100}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discounted Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              value={voucherData.discountedPrice}
                              onChange={(e) =>
                                updateValue("discountedPrice", e.target.value)
                              }
                              name="discountedPrice"
                              // min={0}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>{" "}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Per Person Voucher
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("paxPerVoucher", e.target.value)
                              }
                              name="paxPerVoucher"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("startDate", e.target.value)
                              }
                              name="startDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("startTime", e.target.value)
                              }
                              name="startTime"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("endDate", e.target.value)
                              }
                              name="endDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("endTime", e.target.value)
                              }
                              name="endTime"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Available Time for Booking
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-select"
                              onChange={(e) => setAvailableTime(e.target.value)}
                              value={availableTime || "regular"}
                            >
                              <option value="">Select Time</option>
                              <option value="regular">Regular</option>
                              {/* <option value="slot">Multiple Slot</option> */}
                            </select>
                          </div>
                        </div>

                        <>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Quantity of Seats
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    updateValue("totalSeats", e.target.value)
                                  }
                                  defaultValue={voucherData?.totalSeats}
                                  name="discount"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Start Time / End Time
                              </label>
                              <div className="col-sm-3">
                                <label>Start Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("start", e.target.value)
                                  }
                                  value={regularTime.start}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-sm-3">
                                <label>End Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("end", e.target.value)
                                  }
                                  value={regularTime.end}
                                  className="form-control"
                                />
                              </div>
                            </div>



                          </>
                       
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("buttonText", e.target.value)
                              }
                              name="buttonText"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Title</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {features.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item.title ? item.title : ""
                                          }`}
                                        onChange={(e) => {
                                          updateFeatureField(
                                            index,
                                            "title",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => saveFeature(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deleteFeature(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Cancellation Policy
                          </label>
                          <div className="col-sm-8">
                            <button
                              type="button"
                              onClick={() => setPolicyModal(true)}
                              className="btn btn-primary mb-3"
                            >
                              Update Cancellation Policy
                            </button> 
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <OfferPackageModal
        show={openFeature}
        closeModal={closeFeatureModal}
        addData={addFeatureData}
      />
      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />

      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />

      <EventOrgModal
        show={modelOrg}
        closeEventOrgModal={closeModalEventOrg}
        selectEventOrgRow={selectRowEventOrg}
      />

      <ActivityProviderModal
        show={modelActivity}
        closeModal={closeModalActivity}
        selectRow={selectRowActivity}
      />
       <CancelPolicyModal
        show={policyModal}
        closeModal={closePolicyModal}
        addData={addPolicyData} 
        currentPolicyData={voucherData?.cancelPolicy}       
      />


      <FileUpload message="File Uploading" status={startLoader} />
      {/* modal */}
    </React.Fragment>
  );
}
